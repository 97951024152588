import { colors, theme } from '@config/theme'
import { decorateEntityIds } from '@decorators/decorateEntityIds'
import { findEntityById } from '@decorators/findEntityById.ts'
import { timeFormatter } from '@decorators/number-formatter.ts'
import { api } from '@libs/api'
import Eventbus from '@libs/eventbus'
import {
  Box,
  BoxProps,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Typography,
  useMediaQuery,
} from '@mui/material'
import { resetFilters } from '@store/campFilters/campFiltersSlice.ts'
import { getCampList } from '@store/campSearch/campSearchSlice.ts'
import { setSelectedMetro } from '@store/metro/metroSlice.ts'
import { AddToListDrawer } from '@stories/Components/AddtoList/AddToListDrawer'
import { AgeYearsOld } from '@stories/Components/AgeYearsOld/AgeYearsOld'
import { CampTimes } from '@stories/Components/CampTimes/CampTimes'
import { DatesElement } from '@stories/Components/DateElement/DatesElement.tsx'
import { DayLength } from '@stories/Components/DayLength/DayLength'
import { LunchIncluded } from '@stories/Components/LunchIncluded/LunchIncluded.tsx'
import { MainImage } from '@stories/Components/MainImage/MainImage.tsx'
import { PricePerDay } from '@stories/Components/PricePerDay/PricePerDay'
import { PurchaseOptions } from '@stories/Components/PurchaseOptions/PurchaseOptions.tsx'
import { CloseButton } from '@stories/Icons/CloseButton/CloseButton.tsx'
import { Button } from '@stories/Theme/Button/Button.tsx'
import { Text } from '@stories/Typography/Text/Text.tsx'
import { AxiosError } from 'axios'
import { DateTime } from 'luxon'
import mixpanel from 'mixpanel-browser'
import React, { lazy, useEffect, useState } from 'react'
import { Helmet } from 'react-helmet'
import { GoLinkExternal } from 'react-icons/go'
import { useParams } from 'react-router'
import { Link } from 'react-router-dom'
import styled from 'styled-components'

import { AddToListAndShareButtons, CampBadge, CampDetails } from '../components/CampPage'
import { BadgeInterface, getBadgesFromInterest } from '../libs/helpers'
import redis from '../libs/lockr.ts'
import { Camp } from '../libs/types/Camp'
import { Purchaseoption } from '../libs/types/Purchaseoption'
import { useAppDispatch, useAppSelector } from '../store/hooks'
import CampPageSkeleton from './CampPage.skeleton'

const NotFoundCamp = lazy(() => import('./NotFoundCamp.tsx'))

interface CampPageDetailWrapperProps extends BoxProps {
  noBorder?: boolean
  isFlexDirectionRow?: boolean
}

const CampPageWrapper = styled.section`
  padding: 16px;

  @media${({ theme }) => theme.device['md']} {
    padding: 36px 0 36px 36px;
  }
`

const CampPageDesktopWrapper = styled.section`
  display: flex;
  gap: 52px;
`

const CampPageDesktopSectionWrapper = styled.section`
  flex: 1;
`

const CampPageMobileSectionWrapper = styled.section`
  display: flex;
  flex-direction: column;
  gap: 24px;
  margin-top: 14px;
`

const CampPageBadgesWrapper = styled.section`
  display: flex;
  flex-wrap: nowrap;
  overflow-x: scroll;
  -webkit-overflow-scrolling: touch;
  -ms-overflow-style: -ms-autohiding-scrollbar;
  scroll-behavior: smooth;
  gap: 0 0.5rem;
  -ms-overflow-style: none; // For Internet Explorer and Edge
  scrollbar-width: none; // For Firefox

  .camp-badge {
    min-width: max-content;
    cursor: pointer;
  }

  &::-webkit-scrollbar {
    display: none;
  }

  @media${({ theme }) => theme.device['md']} {
    flex-wrap: wrap;

    .camp-badge {
      margin-bottom: 0.5rem;
    }
  }
`

const CampPageTitle = styled(Text)`
  position: relative;
  font-size: 1.875rem;
  font-family: Ubuntu, sans-serif;
  letter-spacing: -0.094rem;
  line-height: 2.25rem;

  @media${({ theme }) => theme.device['md']} {
    font-size: 3rem;
    letter-spacing: -0.15rem;
    line-height: 3.75rem;
    width: 85%;
  }
`

const CampPageSubtitleWrapper = styled.section`
  margin-top: 0.5rem;
  display: flex;
  flex-direction: column;
`

const CampPageSubtitle = styled(Link)`
  font-size: 1rem;
  font-weight: 600;
  line-height: 1.5rem;
  color: ${colors.brandBlue};
  width: fit-content;

  @media${({ theme }) => theme.device['md']} {
    font-size: 1.125rem;
    line-height: 1.75rem;
  }
`

const CampPageAddToListAndShareButtonsWrapper = styled.section`
  display: none;

  @media${({ theme }) => theme.device['md']} {
    display: flex;
    margin: 24px 0 0 0;
  }
`

const CampPageDetailWrapper = styled(Box)<CampPageDetailWrapperProps>`
  padding: 0;
  border-bottom: ${({ noBorder }) => (noBorder ? 'none' : `1px solid ${colors.brandGray300}`)};
  display: flex;
  flex-direction: ${({ isFlexDirectionRow }) => (isFlexDirectionRow ? 'row' : 'column')};
  gap: ${({ isFlexDirectionRow }) => (isFlexDirectionRow ? '0.5rem' : '0')};

  @media${({ theme }) => theme.device['md']} {
    padding: ${({ noBorder }) => (noBorder ? '16px 0 0 0' : `14px 0`)};
  }

  @container (max-width: 380px) {
    flex-direction: column; // Remains column when container width is 420px or less
    gap: 0;
  }
`

const DatesComponentWrapper = styled(Box)`
  padding: 14px 0;
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  gap: 1.5rem;
  @media${({ theme }) => theme.device['md']} {
    gap: 0.5rem;
    flex-direction: row;
    border-top: 1px solid ${colors.brandGray300};
    border-bottom: 1px solid ${colors.brandGray300};
  }
`
const AgesPriceDayLengthWrapper = styled(Box)`
  display: flex;
  justify-content: space-between;
  flex: 1 1 auto;

  @media${({ theme }) => theme.device['md']} {
    padding: 14px 0;
    margin-top: 1rem;
    border-bottom: 1px solid ${colors.brandGray300};
  }

  > * {
    flex: 1 auto;
  }

  > *:not(:first-child) {
    border-left: 1px solid ${colors.brandGray300}; // Border between components
  }
`

const PurchaseOptionsWrapper = styled(Box)`
  padding: 14px 0;
  display: flex;
  flex-direction: column;
`

const BorderLeftBox = styled(Box)`
  border-left: 1px solid ${colors.brandGray300};
`

const CampPageDetailTitle = styled(Typography)`
  font-size: 16px !important;
  font-weight: 600 !important;
  line-height: 24px !important;
  color: ${colors.brandGray900} !important;
`

const CampPageDetailText = styled(Box)`
  font-size: 16px !important;
  font-weight: 400 !important;
  line-height: 24px !important;
  color: ${colors.brandGray700} !important;
  white-space: pre-line;
`

const ProviderRegistrationLink = ({ id, corporate_provider, registration_link }: Partial<Camp>) => {
  if (!registration_link) {
    return <></>
  }

  const WebsiteLink = styled.a`
    height: 24px;
    display: flex;
    align-items: center;
    color: ${colors.brandBlue};
  `

  return (
    <WebsiteLink
      data-testid="provider_website_link"
      href={registration_link}
      target={'_blank'}
      rel="noreferrer"
      onClick={() => {
        Eventbus.trigger(Eventbus.MIXPANEL_CTA_CLICKED, {
          value: 'Clicked Provider Website Link',
          properties: {
            'Provider ID': corporate_provider,
            'Camp ID': id,
          },
        })
      }}
    >
      <Button
        icon={<GoLinkExternal size="1rem" />}
        size="small"
        style={{ border: 'none', padding: '0', justifyContent: 'start', width: '1.5rem' }}
        title=""
        variant="secondary"
      />
      <span data-testid="provider_website_link_text-value">Provider Website</span>
    </WebsiteLink>
  )
}

const CampPage: React.FC = () => {
  const isLargeScreen: boolean = useMediaQuery(theme.device['md'])
  const isLoggedIn = api.isUserLoggedIn()
  const selectedMetro = useAppSelector((state) => state.metro.selectedMetro)
  const metros = useAppSelector((state) => state.metro.metros)
  const dispatch = useAppDispatch()

  const { campId } = useParams()

  const interestList = useAppSelector((state) => state.campFilters.interestList)
  const providerList = useAppSelector((state) => state.campFilters.providerList)
  const requirementList = useAppSelector((state) => state.campPage.requirementList)

  const [favCamId, setFavCampId] = useState(0)
  const [showLoginRegisterModal, setShowLoginRegisterModal] = useState<boolean>(false)
  const [isLoading, setIsLoading] = useState(true)
  const [CampNotFound, setCampNotFound] = useState(false)
  const [data, setData] = useState({} as Camp)
  const [openBussingOptionModal, setOpenBussingOptionModal] = useState<boolean>(false)

  useEffect(() => {
    if (isLoggedIn && !selectedMetro) {
      const metroId = api.getUserMetro()
      if (metroId && metros.length > 0) {
        dispatch(setSelectedMetro(findEntityById(metroId, metros)))
        dispatch(resetFilters())
        dispatch(getCampList())
      }
    }
  }, [isLoggedIn, metros, selectedMetro])

  useEffect(() => {
    setIsLoading(true)
    api
      .getCampWithId(campId)
      .then((response) => response.data)
      .then((camp: Camp) => {
        camp.bussing_options = []
        api
          .getPurchaseOptions()
          .then((purchaseOptions) => {
            if (purchaseOptions.status === 200) {
              camp.purchase_options_decorated =
                decorateEntityIds<Purchaseoption>(camp.purchase_options, purchaseOptions.data) || []
            }
          })
          .then(() => {
            api
              .getFacilities()
              .then((facilities) => {
                // @ts-ignore
                camp.facility_name = facilities[camp.facility]?.name || ''
                // @ts-ignore
                return facilities[camp.facility]?.address || 0
              })
              .then((facilityAddressId) => {
                api.getAddresses().then((addresses) => {
                  camp.address = addresses[facilityAddressId].address
                  api.getBussingAddresses().then((bussingAddresses) => {
                    camp.bussing_addresses.forEach((bussingAddressId) => {
                      const bussingAddress = bussingAddresses[bussingAddressId]
                      const addressId = bussingAddress?.address
                      if (addressId) {
                        const address = addresses[addressId]
                        if (address.address) {
                          if (camp.bussing_options) {
                            camp.bussing_options.push({ ...bussingAddress, addressName: address.address })
                          }
                        }
                      }
                    })
                  })
                })
              })
          })
          .then(() => {
            setData(camp)
          })
          .then(() => {
            setTimeout(() => {
              setIsLoading(false)
            }, 1000)
          })

        mixpanel.track_pageview({ page: 'CampPage' })
      })
      .catch((error: AxiosError) => {
        if (error.response && error.response.status >= 400 && error.response.status < 600) {
          setIsLoading(false)
          setCampNotFound(true)
        }
      })
  }, [campId])

  const badges = React.useMemo(() => {
    if (!data?.interests) {
      return []
    }

    return getBadgesFromInterest(interestList, data?.interests || [])
  }, [data?.interests, interestList])

  const providerName: string = React.useMemo(() => {
    if (!data?.corporate_provider) {
      return ''
    }

    const corporateProvider = data?.corporate_provider
    const id = corporateProvider ? [corporateProvider] : []
    const provider = decorateEntityIds(id, providerList) || []
    if (provider.length === 0) {
      return ''
    }

    return provider[0].name || ''
  }, [data?.corporate_provider, providerList])

  const programProviderName: string = React.useMemo(() => {
    if (!data?.program_provider) {
      return ''
    }

    const programProvider = data?.program_provider
    const id = programProvider ? [programProvider] : []
    const provider = decorateEntityIds(id, providerList) || []
    if (provider.length === 0) {
      return ''
    }

    return provider[0].name || ''
  }, [data?.program_provider, providerList])

  const refundPolicy: string = React.useMemo(() => {
    if (!data?.corporate_provider) {
      return ''
    }

    const id = data.corporate_provider
    const provider = decorateEntityIds([id], providerList) || []
    if (provider.length === 0) {
      return ''
    }

    return provider[0].refund_policy || ''
  }, [data?.corporate_provider, providerList])

  const scholarshipInfoUrl = React.useMemo(() => {
    if (!data?.corporate_provider) {
      return ''
    }

    const id = data.corporate_provider
    const provider = decorateEntityIds([id], providerList) || []
    if (provider.length === 0) {
      return ''
    }

    return provider[0].scholarship_info_url || ''
  }, [data?.corporate_provider, providerList])

  const ScholarShipElement = ({ noBorder }: { noBorder: boolean }) => (
    <CampPageDetailWrapper noBorder={noBorder}>
      <CampPageDetailTitle>Scholarship</CampPageDetailTitle>
      <CampPageDetailText data-testid="scholarship-value">
        {scholarshipInfoUrl !== '' ? (
          <p>
            <span
              data-testid="scholarship_link"
              style={{
                color: colors.brandBlue,
                fontWeight: 600,
                textDecoration: 'underline',
                cursor: 'pointer',
              }}
              onClick={() => {
                window.open(scholarshipInfoUrl, '_blank')
                mixpanel.track('Clicked Scholarship Link', {
                  'Camp ID': data.id,
                  'Provider ID': data.corporate_provider,
                })
              }}
            >
              Click here
            </span>{' '}
            for more information.
          </p>
        ) : (
          'Unknown'
        )}
      </CampPageDetailText>
    </CampPageDetailWrapper>
  )

  const FacilityLocationElement = ({ noBorder }: { noBorder: boolean }) => (
    <CampPageDetailWrapper noBorder={noBorder}>
      <CampPageDetailTitle data-testid="facility-value">{data.facility_name || 'Unknown location'}</CampPageDetailTitle>
      <CampPageDetailText data-testid="address-value">
        <Link
          data-testid="camp_address_map_link"
          to={`https://maps.google.com/?q=${data.address}`}
          target="_blank"
          onClick={() => {
            mixpanel.track('Clicked Camp Address', {
              'Camp ID': data.id,
              'Provider ID': data.corporate_provider,
            })
          }}
        >
          {data.address || ''}
        </Link>
      </CampPageDetailText>
    </CampPageDetailWrapper>
  )

  const DescriptionElement = ({ noBorder }: { noBorder: boolean }) => (
    <CampPageDetailWrapper noBorder={noBorder}>
      <CampPageDetailTitle>Description</CampPageDetailTitle>
      <CampPageDetailText data-testid="description-value">{data?.description || ''}</CampPageDetailText>
    </CampPageDetailWrapper>
  )

  const RegistrationsOpenElement = ({
    noBorder,
    isFlexDirectionRow,
  }: {
    noBorder: boolean
    isFlexDirectionRow: boolean
  }) => {
    const utcDate = data.registration_open_date ? DateTime.fromISO(data.registration_open_date, { zone: 'utc' }) : null
    return (
      <Box sx={{ containerType: 'inline-size' }}>
        <CampPageDetailWrapper noBorder={noBorder} isFlexDirectionRow={isFlexDirectionRow}>
          <CampPageDetailTitle>Registrations Opens: </CampPageDetailTitle>
          <CampPageDetailText>
            <span data-testid="registration_open_date-value">
              {utcDate ? utcDate.toFormat('MMM. d, yyyy') : 'Unknown'}{' '}
            </span>
            <span data-testid="registration_open_time-value">
              {data.registration_open_time ? ` at ${timeFormatter(data.registration_open_time)}` : ''}
            </span>
          </CampPageDetailText>
        </CampPageDetailWrapper>
      </Box>
    )
  }

  const BussingOptionElement = ({ noBorder }: { noBorder: boolean }) => (
    <CampPageDetailWrapper noBorder={noBorder}>
      <CampPageDetailTitle>Bussing Options</CampPageDetailTitle>
      <CampPageDetailText data-testid="bussing_text-value">
        {data.bussing_options && data.bussing_options.length > 0 ? (
          <p>
            <span
              data-testid="bussing_options_link"
              style={{
                color: colors.brandBlue,
                fontWeight: 600,
                textDecoration: 'underline',
                cursor: 'pointer',
              }}
              onClick={() => {
                setOpenBussingOptionModal(true)
                mixpanel.track('Clicked Bus Stop Link', {
                  'Camp ID': data.id,
                  'Provider ID': data.corporate_provider,
                })
              }}
            >
              Click here
            </span>{' '}
            to see drop-off and pick-up locations.
          </p>
        ) : (
          'N/A'
        )}
      </CampPageDetailText>
    </CampPageDetailWrapper>
  )

  const onAddToListClick = () => {
    mixpanel.track('Camp Heart Button Clicked', {
      'Camp ID': campId,
      'Provider ID': data.corporate_provider,
    })
    api.isUserLoggedIn() ? setFavCampId(parseInt(campId as string)) : setShowLoginRegisterModal(true)
  }

  const InterestElement = () => {
    return (
      <CampPageBadgesWrapper data-testid="interest-badge">
        {badges.length > 0 &&
          badges.map((badge, index) => (
            <Link
              data-testid={`interest-value-${badge.name}`}
              className="camp-badge"
              key={index}
              to={`/camps?filter_checked_interests=[${badge.id}]`}
              target="_blank"
              onClick={() => {
                mixpanel.track('Clicked Interest Tag', {
                  'Camp ID': data.id,
                  'Provider ID': data.corporate_provider,
                  Value: badge.name,
                })
              }}
            >
              <CampBadge data={badge} />
            </Link>
          ))}
      </CampPageBadgesWrapper>
    )
  }

  const RequirementsElement = () => {
    const groups = React.useMemo(() => {
      const groups: BadgeInterface[] = []
      data.requirements?.forEach((id) => {
        const res = findEntityById(id, requirementList)
        if (res) {
          groups.push({ name: res.name, color: 'brandGray', id: id })
        }
      })

      return groups
    }, [data.requirements])

    return (
      <CampPageBadgesWrapper data-testid="affinity-badge">
        {groups.length > 0 &&
          groups.map((group, index) => (
            <Link
              data-testid={`affinity-value-${group.name}`}
              className="camp-badge"
              key={index}
              to={`/camps?filter_checked_affinity_include=[${group.id}]&filter_checked_affinity_exclude=[]`}
              target="_blank"
              onClick={() => {
                mixpanel.track('Clicked Affinity Group Tag', {
                  'Camp ID': data.id,
                  'Provider ID': data.corporate_provider,
                  Value: group.name,
                })
              }}
            >
              <CampBadge data={group} />
            </Link>
          ))}
      </CampPageBadgesWrapper>
    )
  }

  const ProvidersElement = () => {
    return (
      <CampPageSubtitleWrapper>
        <CampPageSubtitle
          data-testid="corporate_provider-value"
          to={`/camps?filter_selected_providers=[${data?.corporate_provider}]`}
          target={'_blank'}
          onClick={() => {
            mixpanel.track('Clicked Provider Link', {
              'Camp ID': data.id,
              'Provider ID': data.corporate_provider,
              Value: data.corporate_provider,
            })
          }}
        >
          {providerName}
        </CampPageSubtitle>
        {programProviderName && (
          <CampPageSubtitle
            data-testid="program_provider-value"
            to={`/camps?filter_selected_providers=[${data?.program_provider}]`}
            target={'_blank'}
            onClick={() => {
              mixpanel.track('Clicked Provider Link', {
                'Camp ID': data.id,
                'Provider ID': data.corporate_provider,
                Value: data.program_provider,
              })
            }}
          >
            {programProviderName}
          </CampPageSubtitle>
        )}
      </CampPageSubtitleWrapper>
    )
  }

  return (
    <>
      {isLoading ? (
        <CampPageSkeleton />
      ) : CampNotFound ? (
        <NotFoundCamp />
      ) : (
        <>
          <Helmet>
            <title>Camperoni Website - {data?.name || ''}</title>
            <link rel="canonical" href={`https://www.camperoni.com/camps/${campId}`} />
          </Helmet>

          <CampPageWrapper>
            {isLargeScreen ? (
              <CampPageDesktopWrapper>
                <CampPageDesktopSectionWrapper>
                  <MainImage
                    image={data?.image_url || ''}
                    title={data?.name}
                    campId={data.id}
                    providerId={data.corporate_provider}
                    bussing_options={data.bussing_options}
                    openBussingOptionModal={openBussingOptionModal}
                    setOpenBussingOptionModal={setOpenBussingOptionModal}
                  />
                  <AgesPriceDayLengthWrapper>
                    <AgeYearsOld {...data} />
                    <DayLength {...data} />
                    <PricePerDay {...data} />
                  </AgesPriceDayLengthWrapper>
                  <PurchaseOptionsWrapper>
                    <PurchaseOptions {...data} />
                  </PurchaseOptionsWrapper>
                  <DatesComponentWrapper>
                    <DatesElement dates={data.dates} />
                    <BorderLeftBox />
                    <CampTimes {...data} />
                  </DatesComponentWrapper>
                  <CampPageDetailWrapper>
                    <CampPageDetailTitle>Refund Policy</CampPageDetailTitle>
                    <CampPageDetailText data-testid="refund_policy-value">
                      {refundPolicy !== '' ? refundPolicy : 'Unknown'}
                    </CampPageDetailText>
                  </CampPageDetailWrapper>
                  <ScholarShipElement noBorder={false} />
                  <CampPageDetailWrapper>
                    <LunchIncluded isIncluded={data.lunch_included} />
                  </CampPageDetailWrapper>
                </CampPageDesktopSectionWrapper>

                <CampPageDesktopSectionWrapper>
                  <CampPageTitle data-testid="title-value" variant={'h1'} fontWeight="bold" color={colors.brandGray900}>
                    {data?.name || ''}
                  </CampPageTitle>
                  <ProvidersElement />
                  <Box sx={{ marginTop: '1.5rem' }}>
                    <InterestElement />
                    <RequirementsElement />
                  </Box>

                  <CampPageAddToListAndShareButtonsWrapper>
                    <AddToListAndShareButtons
                      size="large"
                      withShareButton
                      onClick={onAddToListClick}
                      elTestId="add_to_list_top"
                    />
                  </CampPageAddToListAndShareButtonsWrapper>
                  <Box mt={1}>
                    <FacilityLocationElement noBorder={true} />
                    <BussingOptionElement noBorder={true} />
                    <DescriptionElement noBorder={true} />
                    <RegistrationsOpenElement noBorder={true} isFlexDirectionRow={true} />
                  </Box>
                  <CampDetails data={data} onAddToFavoriteButtonClick={onAddToListClick} />
                </CampPageDesktopSectionWrapper>
              </CampPageDesktopWrapper>
            ) : (
              <CampPageMobileSectionWrapper>
                <Box>
                  <CampPageTitle data-testid="title-value" variant={'h1'} fontWeight="bold" color={colors.brandGray900}>
                    {data?.name || ''}
                  </CampPageTitle>
                  <ProvidersElement />
                </Box>
                <MainImage
                  image={data?.image_url || ''}
                  title={data?.name}
                  campId={data.id}
                  providerId={data.corporate_provider}
                  bussing_options={data.bussing_options}
                  openBussingOptionModal={openBussingOptionModal}
                  setOpenBussingOptionModal={setOpenBussingOptionModal}
                />
                <Box sx={{ display: 'flex', flexDirection: 'column', gap: '0.5rem' }}>
                  <InterestElement />
                  <RequirementsElement />
                </Box>

                <AgesPriceDayLengthWrapper>
                  <AgeYearsOld {...data} />
                  <DayLength {...data} />
                  <PricePerDay {...data} />
                </AgesPriceDayLengthWrapper>
                <PurchaseOptionsWrapper>
                  <PurchaseOptions {...data} />
                </PurchaseOptionsWrapper>

                <FacilityLocationElement noBorder={true} />
                <BussingOptionElement noBorder={true} />
                <DescriptionElement noBorder={true} />
                <DatesComponentWrapper>
                  <CampTimes {...data} />
                  <DatesElement dates={data.dates} />
                </DatesComponentWrapper>
                <RegistrationsOpenElement noBorder={true} isFlexDirectionRow={true} />

                <ProviderRegistrationLink {...data} />

                <CampPageDetailWrapper noBorder={true}>
                  <CampPageDetailTitle>Refund Policy</CampPageDetailTitle>
                  <CampPageDetailText data-testid="refund_policy-value">
                    {refundPolicy !== '' ? refundPolicy : 'Unknown'}
                  </CampPageDetailText>
                </CampPageDetailWrapper>
                <ScholarShipElement noBorder={true} />
                <CampPageDetailWrapper noBorder={true}>
                  <LunchIncluded isIncluded={data.lunch_included} />
                </CampPageDetailWrapper>

                <CampPageAddToListAndShareButtonsWrapper>
                  <AddToListAndShareButtons size="large" withShareButton onClick={onAddToListClick} />
                </CampPageAddToListAndShareButtonsWrapper>
                <CampDetails data={data} onAddToFavoriteButtonClick={onAddToListClick} />
              </CampPageMobileSectionWrapper>
            )}
          </CampPageWrapper>

          <Dialog open={showLoginRegisterModal} data-testid="register_modal">
            <DialogTitle
              sx={{ display: 'flex', justifyContent: 'space-between' }}
              data-testid="register_modal_title-value"
            >
              Sign up for FREE
              <CloseButton onClick={() => setShowLoginRegisterModal(false)} />
            </DialogTitle>
            <DialogContent sx={{ padding: '0 1.5rem' }}>
              <DialogContentText
                sx={{ borderTop: '1px solid #D0D5DD', borderBottom: '1px solid #D0D5DD', padding: '1.5rem 0' }}
                data-testid="register_modal_body-value"
              >
                This feature is available to all registered users. Sign up for FREE today to search camps in your area,
                save favorites, and share with friends.
              </DialogContentText>
              <DialogActions>
                <Link
                  to="/login"
                  data-testid="login_link"
                  onClick={() => {
                    redis.setEx('redirectUrl', 3600, window.location.pathname + window.location.search)
                  }}
                >
                  {' '}
                  <Button title={'Login'} variant={'secondary'} fullWidthOnSmallScreen />
                </Link>
                <Link
                  to="/register"
                  data-testid="register_link"
                  onClick={() => {
                    redis.setEx('redirectUrl', 3600, window.location.pathname + window.location.search)
                  }}
                >
                  <Button title={'Sign up'} variant={'primary'} my="0.5rem" fullWidthOnSmallScreen />
                </Link>
              </DialogActions>
            </DialogContent>
          </Dialog>
          <AddToListDrawer campId={favCamId} onClose={() => setFavCampId(0)} providerId={data.corporate_provider} />
        </>
      )}
    </>
  )
}

export default CampPage
