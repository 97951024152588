import { colors, theme } from '@config/theme'
import { useMediaQuery } from '@mui/material'
import React, { useState } from 'react'
import styled, { ThemeProvider } from 'styled-components'

const ChevronDownIcon: React.FC<{ $isExpanded: boolean }> = ({ $isExpanded }) => {
  const styles = {
    transform: $isExpanded ? 'rotate(180deg)' : 'rotate(0deg)',
    transition: 'transform 0.2s ease',
    lineHeight: '24px',
    display: 'flex',
    justifyContent: 'center',
  }

  return (
    <div style={styles}>
      <svg width="20" height="20" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
          d="M6 9L12 15L18 9"
          stroke={colors.brandGray}
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </svg>
    </div>
  )
}

export const DatesList = ({ dates }: { dates: string[] | null }) => {
  const [isExpanded, setIsExpanded] = useState<boolean>(false)
  const uniqueAndSortedDates = Array.from(new Set((dates || []).sort()))

  const isLargeScreen: boolean = useMediaQuery(theme.device['md'])
  const slicer: 10 | 8 = isLargeScreen ? 10 : 8
  const visibleDates = isExpanded ? uniqueAndSortedDates : uniqueAndSortedDates.slice(0, slicer)

  return (
    <ThemeProvider theme={theme}>
      <DatesGrid data-testid={'date-card'} data-status="default">
        {visibleDates.map((date, index) => {
          const dateObj = new Date(date)
          const day = dateObj.getUTCDate()
          const weekday = dateObj.toLocaleDateString('en-US', { weekday: 'short', timeZone: 'UTC' })
          const monthYear = dateObj.toLocaleDateString('en-US', { month: 'short', year: 'numeric', timeZone: 'UTC' })

          return (
            <DateItem key={index} data-testid={`date_tile_element_${index}-value`}>
              <Month data-testid={`month_year_element_${index}-value`}>{monthYear}</Month>
              <Day data-testid={`date_element_${index}-value`}>{day}</Day>
              <Weekday data-testid={`day_element_${index}-value`}>{weekday}</Weekday>
            </DateItem>
          )
        })}
      </DatesGrid>
      {uniqueAndSortedDates.length > slicer && (
        <Toggle onClick={() => setIsExpanded(!isExpanded)} data-testid={'see-more-dates-button'}>
          <span>{isExpanded ? 'See fewer dates' : 'See more dates'}</span>
          <ChevronDownIcon $isExpanded={isExpanded} />
        </Toggle>
      )}
    </ThemeProvider>
  )
}

// Styled Components
const DatesGrid = styled.div`
  display: grid;
  grid-template-columns: repeat(5, 1fr);
  gap: 16px 8px;
  @media (max-width: 768px) {
    grid-template-columns: repeat(4, 1fr);
  }
`

const DateItem = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 70px;
  position: relative;

  &::after {
    content: '';
    position: absolute;
    right: -4px;
    width: 1px;
    height: 100%;
    background-color: ${colors.brandGray300};
  }

  &:nth-child(4n)::after {
    display: none;
  }

  @media${({ theme }) => theme.device['md']} {
    &:nth-child(4n)::after {
      display: block;
    }

    &:nth-child(5n)::after {
      display: none;
    }
  }
`

const Month = styled.div`
  font-size: 14px;
  line-height: 20px;
  color: ${colors.brandGray700};
`

const Day = styled.div`
  font-size: 24px;
  line-height: 30px;
  color: ${colors.brandGray900};
`

const Weekday = styled.div`
  font-size: 16px;
  line-height: 24px;
  color: ${colors.brandGray700};
`

const Toggle = styled.button`
  background: none;
  border: none;
  color: ${colors.brandGray};
  font-weight: 600;
  display: flex;
  align-items: center;
  cursor: pointer;
  margin-top: 1rem;
  line-height: 1.5rem;
  @media${({ theme }) => theme.device['md']} {
    align-self: flex-start;
  }
  span {
    margin-right: 0.5rem;
  }
`
