import { Icons } from '@assets/svg'
import { Link } from 'react-router-dom'
import styled from 'styled-components'

import redis from '../../../libs/lockr.ts'

const ChangeCity = ({ text, size = '1rem', color }: { text: string; size?: string; color?: string }) => {
  return (
    <ChangeCityLink
      data-testid={'change-metro-link'}
      to={'/choose-your-metro'}
      onClick={() => {
        redis.setEx('redirectUrl', 3600, window.location.pathname + window.location.search)
      }}
    >
      <ChangeCityLinkText $size={size} style={{ color: color ?? '#475467' }}>
        {text}
      </ChangeCityLinkText>
      <Icons.UTurnRightIcon color={color} />
    </ChangeCityLink>
  )
}

export default ChangeCity

const ChangeCityLink = styled(Link)`
  display: flex;
  gap: 8px;
  align-items: center;
`

const ChangeCityLinkText = styled.div<{ $size: string }>`
  font-weight: 600 !important;

  font-size: ${(props) => props.$size} !important;
`
