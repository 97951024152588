import { colors } from '@config/theme'
import { api } from '@libs/api'
import { Alert, Box, Snackbar, useMediaQuery } from '@mui/material'
import { Button } from '@stories/Theme/Button/Button'
import { Input } from '@stories/Theme/Input/Input'
import { Text } from '@stories/Typography/Text/Text'
import mixpanel from 'mixpanel-browser'
import React, { ReactNode, useEffect, useState } from 'react'
import { useParams } from 'react-router'
import styled from 'styled-components'

const ResetPassword = () => {
  useEffect(() => {
    mixpanel.track_pageview({ page: 'ResetPassword' })
  }, [])

  const { uid, token } = useParams()
  const isSmallScreen = useMediaQuery('(max-width:600px)')
  const [isSnackbarOpen, setIsSnackbarOpen] = useState<boolean>(false)
  const [alertMessage, setAlertMessage] = useState<ReactNode>(null)
  const [errorMessage, setErrorMessage] = useState<ReactNode>(null)
  const [isPosting, setIsPosting] = useState<boolean>(false)
  const [passwordAtLeastEightCharacters, setPasswordAtLeastEightCharacters] = useState<boolean>(false)
  const [passwordAtLeastOneSpecialChar, setPasswordAtLeastOneSpecialChar] = useState<boolean>(false)
  const [isPasswordDisabled, setIsPasswordDisabled] = useState<boolean>(true)
  const [passwordsMatch, setPasswordsMatch] = useState<boolean>(false)
  const [passwordsState, setPasswordsState] = useState({
    new_password1: '',
    new_password2: '',
  })

  //eslint-disable-next-line
  const specialCharactersFormat = /[!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?]+/

  const validatePasswordRules = (value: string): boolean => {
    setPasswordAtLeastEightCharacters(value.length >= 8)
    setPasswordAtLeastOneSpecialChar(specialCharactersFormat.test(value) && value.length > 0)
    const result = !(value.length >= 8 && specialCharactersFormat.test(value))
    setIsPasswordDisabled(result)
    return result
  }
  const validatePasswordHandler = (event: React.FormEvent<HTMLInputElement>) => {
    const { name, value } = event.currentTarget
    const passwordsStateUpdate: any = structuredClone(passwordsState)
    passwordsStateUpdate[name] = value
    setPasswordsMatch(passwordsStateUpdate['new_password1'] === passwordsStateUpdate['new_password2'])
    setPasswordsState(passwordsStateUpdate)
    validatePasswordRules(value)
  }

  const resetPasswordForm = (event: React.FormEvent<HTMLFormElement>) => {
    setIsPosting(true)
    setAlertMessage(null)
    setErrorMessage(null)
    event.preventDefault()
    const formData = new FormData(event.currentTarget)

    const { new_password1, new_password2 } = Object.fromEntries(formData.entries()) as {
      new_password1: string
      new_password2: string
    }
    api
      .resetPasswordConfirm(uid, token, new_password1, new_password2)
      .then(() => {
        setIsSnackbarOpen(true)
        setAlertMessage(<p>Your password has been successfully reset.</p>)
      })
      .catch((error) => {
        const response = error.response
        const responseBody = response.data

        if (
          error.status === 400 &&
          responseBody?.status_code === 400 &&
          ((responseBody?.token && JSON.stringify(responseBody?.token) == JSON.stringify(['Invalid value'])) ||
            (responseBody?.uid && JSON.stringify(responseBody?.uid) == JSON.stringify(['Invalid value'])))
        ) {
          setIsSnackbarOpen(true)
          setErrorMessage(
            <p>
              We are sorry, we weren’t able to find your account and reset your password. Please contact support at{' '}
              <a href="mailto:email@camperoni.com">email@camperoni.com</a>
            </p>
          )
          mixpanel.track('Error', {
            'Error Type': 'Request Error',
            'Error Code': error?.status,
            'Request Type': 'HTTP POST',
            'Error Name': 'Password reset failed',
            'Error Group': 'Password/Verification',
            'Error Info': `HTTP Response: ${JSON.stringify(responseBody)}`,
          })
        } else {
          setErrorMessage(
            <p>
              We are sorry, there was an unexpected error and we weren’t able to reset your password. Please contact
              support at <a href="mailto:email@camperoni.com">email@camperoni.com</a>
            </p>
          )
          setIsSnackbarOpen(true)
          mixpanel.track('Error', {
            'Error Type': 'Request Error',
            'Error Code': error?.status,
            'Request Type': 'HTTP POST',
            'Error Name': 'Password reset failed - Reason unknown',
            'Error Group': 'Password/Verification',
            'Error Info': `HTTP Response: ${JSON.stringify(responseBody)}`,
          })
        }
      })
      .finally(() => setIsPosting(false))
  }

  return (
    <AuthLayoutWrapper>
      <Box sx={{ padding: isSmallScreen ? '3rem 1.5rem' : '5rem 6rem' }}>
        <Text
          variant={'h1'}
          fontFamily="Ubuntu"
          fontSize={isSmallScreen ? '30px' : '48px'}
          fontWeight="bold"
          color={colors.brandGray900}
          mb={isSmallScreen ? '2rem' : '4rem'}
        >
          Reset password
        </Text>
        <form id={'reset-password-form'} onSubmit={resetPasswordForm}>
          <Box
            display="flex"
            alignItems={!isSmallScreen ? 'center' : ''}
            flexDirection={isSmallScreen ? 'column' : 'row'}
            sx={{
              paddingBottom: '1rem',
              borderBottom: isSmallScreen ? '' : `1px solid ${colors.brandGray50}`,
              paddingY: isSmallScreen ? '0.75rem' : '1.7rem',
            }}
          >
            <Text
              style={{ width: isSmallScreen ? 'auto' : '280px' }}
              variant={'label'}
              fontSize={'14px'}
              fontWeight="semibold"
              color={colors.brandGray700}
              mb={isSmallScreen ? '0.75rem' : ''}
            >
              New password
            </Text>
            <Input
              type="password"
              placeholder="*****"
              style={{ width: isSmallScreen ? '100%' : '484px' }}
              onChange={validatePasswordHandler}
              name={'new_password1'}
              disabled={isPosting}
            />
          </Box>
          <Box
            display="flex"
            alignItems={!isSmallScreen ? 'center' : ''}
            flexDirection={isSmallScreen ? 'column' : 'row'}
            sx={{
              paddingBottom: '1rem',
              borderBottom: isSmallScreen ? '' : `1px solid ${colors.brandGray50}`,
              paddingY: isSmallScreen ? '0.75rem' : '1.7rem',
            }}
          >
            <Text
              style={{ width: isSmallScreen ? 'auto' : '280px' }}
              variant={'label'}
              fontSize={'14px'}
              fontWeight="semibold"
              color={colors.brandGray700}
              mb={isSmallScreen ? '0.75rem' : ''}
            >
              Re-enter new password
            </Text>
            <Input
              type="password"
              placeholder="*****"
              style={{ width: isSmallScreen ? '100%' : '484px' }}
              onChange={validatePasswordHandler}
              name={'new_password2'}
              disabled={isPosting}
            />
          </Box>
          <div className="auth-content">
            <div className="password-checker">
              <svg xmlns="http://www.w3.org/2000/svg" width="20" height="21" viewBox="0 0 20 21" fill="none">
                <rect
                  y="0.027771"
                  width="20"
                  height="20"
                  rx="10"
                  fill={passwordAtLeastEightCharacters ? colors.brandGreen : colors.brandGray300}
                />
                <path
                  d="M6.25 10.0278L8.75 12.5278L13.75 7.52777"
                  stroke="white"
                  strokeWidth="2"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
              </svg>
              <span>Must be at least 8 characters</span>
            </div>
            <div className="password-checker">
              <svg xmlns="http://www.w3.org/2000/svg" width="20" height="21" viewBox="0 0 20 21" fill="none">
                <rect
                  y="0.027771"
                  width="20"
                  height="20"
                  rx="10"
                  fill={passwordAtLeastOneSpecialChar ? colors.brandGreen : colors.brandGray300}
                />
                <path
                  d="M6.25 10.0278L8.75 12.5278L13.75 7.52777"
                  stroke="white"
                  strokeWidth="2"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
              </svg>
              <span>Must contain one special character</span>
            </div>
            <div className="password-checker">
              <svg xmlns="http://www.w3.org/2000/svg" width="20" height="21" viewBox="0 0 20 21" fill="none">
                <rect
                  y="0.027771"
                  width="20"
                  height="20"
                  rx="10"
                  fill={passwordsMatch ? colors.brandGreen : colors.brandGray300}
                />
                <path
                  d="M6.25 10.0278L8.75 12.5278L13.75 7.52777"
                  stroke="white"
                  strokeWidth="2"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
              </svg>
              <span>Passwords must match</span>
            </div>
          </div>
          <Box
            display="flex"
            justifyContent={'flex-end'}
            alignItems={'center'}
            sx={{
              paddingY: isSmallScreen ? '0.75rem' : '1.7rem',
            }}
          >
            <Button
              title={'Save'}
              variant={'primary'}
              ml="0.5rem"
              disabled={isPasswordDisabled || !passwordsMatch || isPosting}
            />
          </Box>
        </form>
      </Box>
      <Snackbar
        data-testid="error-message"
        open={isSnackbarOpen}
        // autoHideDuration={6000}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
        onClose={() => {
          setIsSnackbarOpen(false)
        }}
      >
        <Alert severity={errorMessage ? 'error' : 'info'}>{alertMessage ? alertMessage : errorMessage}</Alert>
      </Snackbar>
    </AuthLayoutWrapper>
  )
}

export default ResetPassword

// Styled Components
const AuthLayoutWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  .auth-nav {
    width: 100%;
    left: 0;
    top: 0;
    display: flex;
    padding: 18px 24px;
    @media${({ theme }) => theme.device['md']} {
      padding: 30px;
    }
  }
  .auth-content {
    max-width: 390px;
    padding: 16px;
    width: 100%;
    position: relative;
    z-index: 5;
    .auth-title {
      color: var(${colors.brandDefault});
      text-align: center;
      font-family: Ubuntu;
      margin-top: 36px;
      margin-bottom: 8px;
      font-size: 30px;
      font-style: normal;
      font-weight: 700;
      line-height: 38px; /* 126.667% */
      letter-spacing: -1.5px;
      &.register-title {
        //margin-top: 1rem;
      }
      @media ${({ theme }) => theme.device.sm} {
        margin-top: 24px;
        font-size: 36px;
        line-height: 44px;
        letter-spacing: -1.8px;
        margin-bottom: 16px;
      }
    }
    .auth-subheading {
      color: var(--gray-600, #475467);
      text-align: center;
      margin-bottom: 32px;
      @media ${({ theme }) => theme.device.sm} {
        font-size: 16px;
        font-style: normal;
        font-weight: 400;
        line-height: 24px;
      }
    }
    .auth-input {
      width: 100%;
      margin-bottom: 24px;
      label {
        color: var(--gray-700, #344054);
        font-size: 14px;
        font-style: normal;
        font-weight: 500;
        line-height: 20px; /* 142.857% */
        margin-bottom: 6px;
        display: block;
      }
      input {
        width: 100%;
      }
    }
    .password-checker {
      display: flex;
      align-items: center;
      justify-content: flex-start;
      gap: 8px;
      margin-bottom: 12px;
      span {
        color: var(--gray-600, #475467);
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: 20px; /* 142.857% */
      }
    }
    .remember-me-section {
      display: flex;
      justify-content: flex-end;
      align-items: center;
      margin-bottom: 24px;
      width: 100%;
      .check-box {
        display: flex;
        align-items: center;
        span {
          padding: 0px 4px 0px 0px;
        }
        label {
          color: var(--gray-700, #344054);
          font-size: 14px;
          font-style: normal;
          font-weight: 500;
          line-height: 20px; /* 142.857% */
        }
      }
      .forgot-link {
        color: var(--brand-700, #323d77);
        font-size: 14px;
        font-style: normal;
        font-weight: 600;
        line-height: 20px; /* 142.857% */
      }
    }
    .auth-move-link {
      margin-top: 32px;
      text-align: center;
      color: var(--gray-600, #475467);
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: 20px; /* 142.857% */
      a {
        color: var(--brand-700, #323d77);
        font-size: 14px;
        font-style: normal;
        font-weight: 600;
        line-height: 20px; /* 142.857% */
      }
    }
  }
`
