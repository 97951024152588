import { apiResponse } from '@libs/api'
import { City } from '@libs/types/City.ts'
import { Metro } from '@libs/types/Metro.ts'
import { Provider } from '@libs/types/Provider.ts'
import { Schoolbreak } from '@libs/types/Schoolbreak.ts'
import { User } from '@libs/types/User.ts'
import { createSlice } from '@reduxjs/toolkit'

import redis from '../../libs/lockr.ts'

interface MetroSliceState {
  metros: Metro[]
  allCities: City[]
  selectedMetro: Metro | null
  sessionStorageSchoolBreaksList: Schoolbreak[]
  sessionStorageProviderList: Provider[]
}

export const initialState: MetroSliceState = {
  metros: [],
  allCities: [],
  selectedMetro: null,
  sessionStorageSchoolBreaksList: [],
  sessionStorageProviderList: [],
}

export const metroReducer = createSlice({
  name: 'metro',
  initialState,
  reducers: {
    getSelectedMetro: () => {},
    setSelectedMetro: (state, action) => {
      const metro = action.payload
      let newMetroId = null
      if (metro?.id) {
        newMetroId = metro.id
      }

      const getUserByKey = redis.get('api:getUserByKey') as apiResponse<User>
      if (getUserByKey?.data?.metro) {
        getUserByKey.data.metro = newMetroId
        redis.setEx('api:getUserByKey', Number(import.meta.env?.VITE_CACHE_TTL || 120), getUserByKey)
      }

      const user = redis.get('user') as User
      redis.set('user', { ...user, ...{ metro: newMetroId } })
      state.selectedMetro = metro || null
    },
    getMetros: () => {},
    setMetros: (state, action) => {
      state.metros = action.payload
    },
    getCities: () => {},
    setCities: (state, action) => {
      state.allCities = action.payload
    },
    setSessionStorageSchoolBreaksList: (state, action) => {
      state.sessionStorageSchoolBreaksList = action.payload
      sessionStorage['api:schoolbreaks'] = JSON.stringify(action.payload)
    },
    getSessionStorageSchoolBreaksList: () => {},
    setSessionStorageProviderList: (state, action) => {
      state.sessionStorageProviderList = action.payload
      sessionStorage['api:providerList'] = JSON.stringify(action.payload)
    },
  },
})

export const {
  getMetros,
  setMetros,
  getCities,
  setCities,
  getSelectedMetro,
  setSelectedMetro,

  getSessionStorageSchoolBreaksList,
  setSessionStorageSchoolBreaksList,
  setSessionStorageProviderList,
} = metroReducer.actions

export default metroReducer.reducer
