import { Icons } from '@assets/svg'
import { colors, theme } from '@config/theme'
import { DatesList } from '@stories/Components/DatesList/DatesList.tsx'
import { DatesOneDay } from '@stories/Components/DatesOneDay/DatesOneDay.tsx'
import React from 'react'
import styled, { ThemeProvider } from 'styled-components'

type DatesElementProps = { dates: string[] | null }
export const DatesElement: React.FC<DatesElementProps> = ({ dates }) => {
  return (
    <ThemeProvider theme={theme}>
      <Container data-testid={'date-module'}>
        <Title>
          <Icons.CalendarIcon /> Dates
        </Title>
        {dates && dates.length === 1 ? <DatesOneDay date={dates[0]} /> : <DatesList dates={dates} />}
      </Container>
    </ThemeProvider>
  )
}

// Styled Components
const Container = styled.div`
  display: flex;
  flex-direction: column;
  //max-width: 400px;
  min-width: 100px;
  align-items: center;

  @media${({ theme }) => theme.device['md']} {
    min-width: 300px;
  }
`

const Title = styled.div`
  display: flex;
  align-items: center;
  font-size: 1rem;
  line-height: 1.25rem;
  margin-bottom: 1rem;
  align-self: flex-start;
  color: ${colors.brandGray900};
  font-weight: 600;
  svg {
    margin-right: 0.5rem;
  }
`
