import { theme } from '@config/theme'
import isPropValid from '@emotion/is-prop-valid'
import { THEME_ID, ThemeProvider as MUIThemeProvider } from '@mui/material/styles'
import * as Sentry from '@sentry/browser'
import mixpanel from 'mixpanel-browser'
import { lazy, Suspense, useEffect } from 'react'
import ReactGA from 'react-ga4'
import { BrowserRouter, Route, Routes } from 'react-router-dom'
import { Redirect } from 'react-router6-redirect'
import { StyleSheetManager, ThemeProvider } from 'styled-components'

import { camperoniMuiTheme } from './config/themeOptions.tsx'
import CleanLayout from './Layouts/CleanLayout.tsx'
import HomepageLayout from './Layouts/HomepageLayout.tsx'
import ListPageLayout from './Layouts/ListPageLayout.tsx'
import SharableFavoritesLayout from './Layouts/SharableFavoritesLayout.tsx'
import redis from './libs/lockr.ts'
import { User } from './libs/types/User.ts'
import BlogPage from './pages/BlogPage.tsx'
import CampPageSkeleton from './pages/CampPage.skeleton.tsx'
import CampPage from './pages/CampPage.tsx'
import ChooseMetro from './pages/ChooseMetro.tsx'
import ContactPage from './pages/ContactPage.tsx'
import EmailVerify from './pages/EmailVerify.tsx'
import Error404Skeleton from './pages/Error404.skeleton.tsx'
import FavoritesSkeleton from './pages/Favorites.skeleton.tsx'
import ForgotPassword from './pages/ForgotPassword.tsx'
import HomePageSkeleton from './pages/HomePage.skeleton.tsx'
import List from './pages/List.tsx'
import LoginSkeleton from './pages/Login.skeleton.tsx'
import RegisterSkeleton from './pages/Register.skeleton.tsx'
import ResetPassword from './pages/ResetPassword.tsx'
import Settings from './pages/Settings.tsx'
import SharableFavorites from './pages/SharableFavorites.tsx'
import Terms from './pages/Terms.tsx'
import ThankYouPageSkeleton from './pages/ThankYouPage.skeleton.tsx'
import UserTermsSkeleton from './pages/UserTermsSkeleton.tsx'
import { MetroSyncProvider } from './providers/MetroSyncProvider.tsx'

const Favorites = lazy(() => import('./pages/Favorites'))
const Register = lazy(() => import('./pages/Register'))
const Login = lazy(() => import('./pages/Login'))
const HomePage = lazy(() => import('./pages/HomePage'))
const UserTerms = lazy(() => import('./pages/UserTerms'))
const ProviderTerms = lazy(() => import('./pages/ProviderTerms'))
const PrivacyPolicy = lazy(() => import('./pages/PrivacyPolicy'))
const Error404 = lazy(() => import('./pages/Error404'))
const ThankYouPage = lazy(() => import('./pages/ThankYouPage'))

import('./libs/mixpanel-events')

const App = () => {
  const user = redis.get('user') as User
  const userId = (user?.id || '').toString()
  const gaOptions = {
    gaOptions: {
      userId: userId,
    },
  }

  ReactGA.initialize('G-5FN9Q4XNN3', gaOptions)

  mixpanel.init(import.meta.env.VITE_MIXPANEL_TOKEN as string, { track_pageview: false, batch_flush_interval_ms: 1000 })

  useEffect(() => {
    const utmTags = new URLSearchParams(window.location.search)
    redis.setUtmTags(utmTags)
    if (user?.id) {
      mixpanel.identify(userId)
      Sentry.setUser({ id: userId })
    }
  }, [])

  useEffect(() => {
    const logoutChannel = new BroadcastChannel('logout')
    logoutChannel.addEventListener('message', (event) => {
      if (event.origin !== window.location.origin) {
        return
      }

      if (event.data === 'logout') {
        return window.location.reload()
      }
    })
    return () => logoutChannel.close()
  }, [])

  const search = window.location.search

  return (
    <StyleSheetManager shouldForwardProp={isPropValid}>
      <ThemeProvider theme={theme}>
        <MUIThemeProvider theme={{ [THEME_ID]: camperoniMuiTheme }}>
          <MetroSyncProvider>
            <BrowserRouter>
              <Routes>
                <Route
                  path={'/'}
                  element={
                    <HomepageLayout>
                      <Suspense fallback={<HomePageSkeleton />}>
                        <HomePage />
                      </Suspense>
                    </HomepageLayout>
                  }
                />
                <Route
                  path={'/login'}
                  element={
                    <Suspense fallback={<LoginSkeleton />}>
                      <Login />
                    </Suspense>
                  }
                />
                <Route
                  path={'/register'}
                  element={
                    <Suspense fallback={<RegisterSkeleton />}>
                      <Register />
                    </Suspense>
                  }
                />
                <Route path={'/forgotpassword'} element={<ForgotPassword />} />
                <Route
                  path={`/password/reset/confirm/:uid/:token`}
                  element={
                    <HomepageLayout>
                      <ResetPassword />
                    </HomepageLayout>
                  }
                />
                <Route
                  path={`/registration/verify-email/:key`}
                  element={
                    <HomepageLayout>
                      <EmailVerify />
                    </HomepageLayout>
                  }
                />
                <Route
                  path={'/blog'}
                  element={
                    <HomepageLayout pageTitle={'Blog'}>
                      <BlogPage />
                    </HomepageLayout>
                  }
                />
                <Route
                  path={'/feedback'}
                  element={
                    <HomepageLayout>
                      <HomePage />
                    </HomepageLayout>
                  }
                />
                <Route
                  path={'/choose-your-metro'}
                  element={
                    <HomepageLayout pageTitle={'choose-your-metro'}>
                      <ChooseMetro />
                    </HomepageLayout>
                  }
                />
                <Route
                  path={'/favorites'}
                  element={
                    <HomepageLayout pageTitle={''} requireAuthentication={true}>
                      <Suspense fallback={<FavoritesSkeleton />}>
                        <Favorites />
                      </Suspense>
                    </HomepageLayout>
                  }
                />
                <Route
                  path={'/userlist/:sharableId'}
                  element={
                    <SharableFavoritesLayout>
                      <SharableFavorites />
                    </SharableFavoritesLayout>
                  }
                />
                <Route
                  path={'/settings'}
                  element={
                    <HomepageLayout pageTitle={''} requireAuthentication={true}>
                      <Settings />
                    </HomepageLayout>
                  }
                />

                <Route path={'/list'} element={<Redirect to={`/camps${search}`} />} />
                <Route path={'/list/:campId'} element={<Redirect to={`/camps/:campId${search}`} />} />
                <Route
                  path={'/camps'}
                  element={
                    <ListPageLayout>
                      <List />
                    </ListPageLayout>
                  }
                />
                <Route
                  path={'/camps/:campId'}
                  element={
                    <HomepageLayout pageTitle={''}>
                      <Suspense fallback={<CampPageSkeleton />}>
                        <CampPage />
                      </Suspense>
                    </HomepageLayout>
                  }
                />
                <Route
                  path={'/contact'}
                  element={
                    <HomepageLayout pageTitle={'Contact'}>
                      <ContactPage />
                    </HomepageLayout>
                  }
                />
                <Route
                  path={'/privacy-policy'}
                  element={
                    <HomepageLayout pageTitle={'Terms'}>
                      <Suspense fallback={<UserTermsSkeleton />}>
                        <PrivacyPolicy />
                      </Suspense>
                    </HomepageLayout>
                  }
                />
                <Route
                  path={'/thank-you'}
                  element={
                    <HomepageLayout>
                      <Suspense fallback={<ThankYouPageSkeleton />}>
                        <ThankYouPage />
                      </Suspense>
                    </HomepageLayout>
                  }
                />
                <Route
                  path={'/terms'}
                  element={
                    <HomepageLayout pageTitle={'Terms'}>
                      <Terms />
                    </HomepageLayout>
                  }
                />
                <Route
                  path={'/terms/user'}
                  element={
                    <HomepageLayout pageTitle={'Terms'}>
                      <Suspense fallback={<UserTermsSkeleton />}>
                        <UserTerms />
                      </Suspense>
                    </HomepageLayout>
                  }
                />
                <Route
                  path={'/terms/advertiser'}
                  element={
                    <HomepageLayout pageTitle={'Terms'}>
                      <Suspense fallback={<UserTermsSkeleton />}>
                        <ProviderTerms />
                      </Suspense>
                    </HomepageLayout>
                  }
                />
                <Route path={'/clean'} element={<CleanLayout />} />
                <Route
                  path={'/*'}
                  element={
                    <HomepageLayout>
                      <Suspense fallback={<Error404Skeleton />}>
                        <Error404 />
                      </Suspense>
                    </HomepageLayout>
                  }
                />
              </Routes>
            </BrowserRouter>
          </MetroSyncProvider>
        </MUIThemeProvider>
      </ThemeProvider>
    </StyleSheetManager>
  )
}

export default App
