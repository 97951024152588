import { colors } from '@config/theme'
import Eventbus from '@libs/eventbus'
import { Zipcode } from '@libs/types/Zipcode.ts'
import { SlotComponentProps } from '@mui/base'
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline'
import {
  Box,
  CircularProgress,
  debounce,
  FormControl,
  FormControlLabel,
  InputAdornment,
  InputLabel,
  Slider,
  Switch,
  TextField,
  Typography,
} from '@mui/material'
import { SliderComponentsPropsOverrides, SliderOwnerState } from '@mui/material/Slider/Slider'
import {
  setDistanceAllowBussing,
  setDistanceAllowOvernight,
  setDistanceIsZipCodeIncluded,
  setDistanceMiles,
  setDistanceZipCode,
} from '@store/campFilters/campFiltersSlice'
import { useAppDispatch, useAppSelector } from '@store/hooks.ts'
import React, { useCallback, useEffect, useState } from 'react'

import redis from '../../../../libs/lockr.ts'

const distanceMarks = [
  {
    value: 1,
    label: '1 mile',
  },
  {
    value: 100,
    label: '100 miles',
  },
]

const valueText = (value: number): string => {
  return `$${value} `
}

const Distance = () => {
  const dispatch = useAppDispatch()

  const distance = useAppSelector((state) => state.campFilters.filters.distance)
  const zipCodeList = useAppSelector((state) => state.campSearch.zipCodeList) || []
  const isCampListLoading = useAppSelector((state) => state.campSearch.isLoading)
  const isFilteringLoading = useAppSelector((state) => state.campSearch.isFilteringLoading)
  const { miles, zipCode, allowBussing, allowOvernight, isZipCodeIncluded } = distance

  const [zipCodeState, setZipCodeState] = useState(zipCode)
  const [milesState, setMilesState] = useState<number>(miles)
  const [isInputDisabled, setIsInputDisabled] = useState<boolean>(false)

  const currentZipObj = Object.values(zipCodeList).find((zip: Zipcode) => zip.zipcode === zipCode)

  const validateZipCode = (zip: string): boolean => {
    return zip.length === 5
  }
  useEffect(() => {
    Eventbus.on(Eventbus.DISTANCE_CLEAR_FILTER, handleClearAllFilters)
    return () => Eventbus.off(Eventbus.DISTANCE_CLEAR_FILTER, handleClearAllFilters)
  }, [])

  const handleClearAllFilters = () => {
    setZipCodeState('')
    setIsInputDisabled(false)
    setMilesState(100)
  }

  useEffect(() => {
    const isZipCodeIncluded = Object.values(zipCodeList).some((zipObj) => zipObj.zipcode === zipCode)
    if (isZipCodeIncluded) {
      dispatch(setDistanceIsZipCodeIncluded(isZipCodeIncluded))
    }
  }, [zipCode, zipCodeList])

  function handleChange(_event: Event, value: number | number[]) {
    setMilesState(value as number)
    Eventbus.trigger(Eventbus.RESET_PAGING)
  }

  const handleDistanceChange = useCallback(
    debounce((_event: React.SyntheticEvent | Event, newValue: number | number[]) => {
      setMilesState(newValue as number)
      dispatch(setDistanceMiles(newValue as number))
      Eventbus.trigger(Eventbus.MIXPANEL_FILTER_ACTION, {
        filter: 'Distance Threshold Changed',
        value: newValue,
      })
      Eventbus.trigger(Eventbus.RESET_PAGING)
    }, 300),
    [dispatch]
  )

  const handleOvernightChange = (event: React.ChangeEvent<HTMLInputElement>): void => {
    dispatch(setDistanceAllowOvernight(event.target.checked))
    Eventbus.trigger(Eventbus.MIXPANEL_FILTER_ACTION, {
      filter: 'Distance Ignore Overnight Changed',
      value: event.target.checked ? 'on' : 'off',
    })
    Eventbus.trigger(Eventbus.RESET_PAGING)
  }

  const handleBussingChange = (event: React.ChangeEvent<HTMLInputElement>): void => {
    dispatch(setDistanceAllowBussing(event.target.checked))
    Eventbus.trigger(Eventbus.MIXPANEL_FILTER_ACTION, {
      filter: 'Distance Include Bussing Changed',
      value: event.target.checked ? 'on' : 'off',
    })
    Eventbus.trigger(Eventbus.RESET_PAGING)
  }

  // Debounced function to update Redux when zip is valid
  const updateZipCode = useCallback(
    debounce((value: string) => {
      const isZipCodeIncluded = Object.values(zipCodeList).some((zipObj) => zipObj.zipcode === value)
      dispatch(setDistanceIsZipCodeIncluded(isZipCodeIncluded))
      dispatch(setDistanceZipCode(value))
      Eventbus.trigger(Eventbus.RESET_PAGING)
      setIsInputDisabled(false)

      if (isZipCodeIncluded) {
        Eventbus.trigger(Eventbus.MIXPANEL_FILTER_ACTION, {
          filter: 'Correct Zipcode Entered',
          value: value,
        })
      }
    }, 300),
    [dispatch, zipCodeList]
  )

  const handleZipCodeChange = (event: React.ChangeEvent<HTMLInputElement>): void => {
    const value = event.target.value.replace(/[^0-9]/g, '')

    setZipCodeState(value)
    updateZipCode(value)

    // If the zip is valid, update Redux state.
    if (validateZipCode(value)) {
      setIsInputDisabled(true)
    } else {
      redis.rm('api:v2:distances')
    }
  }

  const getErrorMessageForZipCodeFilter = (): string => {
    // If input is disabled or list is loading, no error message is needed
    if (zipCodeState === '' || isInputDisabled || isCampListLoading) {
      return ''
    }

    // If zip code is complete, check further conditions
    if (validateZipCode(zipCodeState)) {
      if (!isZipCodeIncluded) {
        return 'Sorry, your ZIP code was not found for this metro area'
      }
      if (currentZipObj && (currentZipObj.dirty || !currentZipObj.latitude || !currentZipObj.longitude)) {
        return 'Sorry, your ZIP code has triggered an error. Please contact us for support.'
      }
    } else {
      // Handle incomplete zip codes
      return 'Please enter a 5-digit zipcode'
    }
    // No errors, return an empty string
    return ''
  }

  const isElementDisabled =
    !validateZipCode(zipCodeState) ||
    !isZipCodeIncluded ||
    (currentZipObj && (currentZipObj.dirty || !currentZipObj.latitude || !currentZipObj.longitude))

  const getInputAdornmentContent = useCallback(() => {
    if (zipCodeState !== '' && !validateZipCode(zipCodeState)) {
      return <ErrorOutlineIcon color={'error'} />
    } else if (isInputDisabled || isFilteringLoading) {
      return <CircularProgress size={12} />
    }
    return null
  }, [zipCodeState, isInputDisabled, isFilteringLoading])

  return (
    <>
      <FormControl variant="standard" sx={{ pt: 3, mb: 0.5 }} fullWidth>
        <InputLabel sx={{ mt: -3 }} htmlFor="distance-field">
          <Typography variant="body2" component={'span'}>
            Your location
          </Typography>
        </InputLabel>
        <TextField
          error={zipCodeState !== '' && !validateZipCode(zipCodeState)}
          InputProps={{
            endAdornment: <InputAdornment position={'end'}>{getInputAdornmentContent()}</InputAdornment>,
          }}
          size="small"
          placeholder="Enter a zipcode to calculate distance"
          id="distance-field"
          variant="outlined"
          inputProps={{ maxLength: 5, 'data-testid': 'zipcode-input' }}
          disabled={isInputDisabled || isCampListLoading}
          onChange={handleZipCodeChange}
          value={zipCodeState}
        />

        <Typography
          mt={0.5}
          color={colors.brandError500}
          fontSize={'14px'}
          data-testid="error-message-zipcode-input"
          sx={{ minHeight: '42px' }}
        >
          {getErrorMessageForZipCodeFilter()}
        </Typography>
      </FormControl>
      <Box>
        <Typography variant="body1" fontWeight={600} mb={2}>
          Distance (miles)
        </Typography>
        <Box pt={4} px={3} mb={2}>
          <Slider
            aria-controls="slider-distance-container"
            disabled={isElementDisabled}
            slotProps={{
              input: {
                'data-testid': 'slider-distance',
              } as SlotComponentProps<'input', SliderComponentsPropsOverrides, SliderOwnerState>,
            }}
            getAriaValueText={valueText}
            value={milesState}
            onChange={handleChange}
            valueLabelDisplay="on"
            onChangeCommitted={handleDistanceChange}
            marks={distanceMarks}
            min={1}
            max={100}
            step={1}
          />
        </Box>
        <Box>
          <FormControlLabel
            sx={{ mb: 2 }}
            control={
              <Switch
                data-testid="bussing-switch"
                onChange={handleBussingChange}
                sx={{ m: 1 }}
                checked={allowBussing}
                disabled={isElementDisabled}
              />
            }
            label="Include camps with bussing locations within distance"
          />
          <FormControlLabel
            control={
              <Switch
                data-testid="overnight-switch"
                onChange={handleOvernightChange}
                sx={{ m: 1 }}
                checked={allowOvernight}
                disabled={isElementDisabled}
              />
            }
            label="Ignore distance for overnight camps"
          />
        </Box>
      </Box>
    </>
  )
}

export default Distance
