import { Icons } from '@assets/svg'
import { colors } from '@config/theme'
import { api } from '@libs/api'
import { Alert, CircularProgress, Dialog, DialogTitle, Snackbar } from '@mui/material'
import { useAppDispatch } from '@store/hooks.ts'
import { getUserList } from '@store/userList/userListSlice.ts'
import { CloseButton } from '@stories/Icons/CloseButton/CloseButton.tsx'
import { Button } from '@stories/Theme/Button/Button'
import { Input } from '@stories/Theme/Input/Input'
import mixpanel from 'mixpanel-browser'
import { FC, ReactNode, useEffect, useRef, useState } from 'react'
import styled from 'styled-components'

import redis from '../../../libs/lockr.ts'

interface CreateNewListDialogProps {
  open: boolean
  setOpen: (visible: boolean) => void
  addVisibleItemToFavoritesList?: (value: boolean, favouriteListId: number) => void
}

const CreateNewListDialog: FC<CreateNewListDialogProps> = ({ open, setOpen, addVisibleItemToFavoritesList }) => {
  const inputRef = useRef(null)
  const [errorMessage, setErrorMessage] = useState<ReactNode>(null)
  const [isLoading, setIsLoading] = useState<boolean>(false)
  const dispatch = useAppDispatch()
  const onSave = async () => {
    const inputField: any = inputRef.current

    if (inputField.value === '') {
      setErrorMessage(<p>List Name cannot be empty</p>)
      setIsLoading(false)
      return
    }

    if (inputRef.current) {
      setIsLoading(true)
      try {
        const response = await api.createUserList(inputField.value)

        redis.rm('api:userList')
        dispatch(getUserList())
        setOpen(false)
        mixpanel.track('Created New List')

        if (addVisibleItemToFavoritesList) {
          addVisibleItemToFavoritesList(true, response.data.id)
        }
      } catch (error: any) {
        setErrorMessage(
          // Show error message and log to Mixpanel after retries have been exhausted or time limit is reached
          <p>
            Sorry, we couldn&apos;t add a new list. Please try again. If the problem persists, please contact us at{' '}
            <a href="mailto:email@camperoni.com">email@camperoni.com</a>
          </p>
        )
        mixpanel.track('Error', {
          'Error Type': 'Request Error',
          'Error Code': error?.response?.status,
          'Request Type': 'HTTP PATCH',
          'Error Name': 'Add a new list failed',
        })
      }

      setIsLoading(false)
    }
  }

  useEffect(() => {
    if (open) {
      mixpanel.track('Create New List Button')
    }
  }, [open])

  return (
    <CreateNewListDialogWrapper onClose={() => setOpen(false)} open={open}>
      <DialogTitle alignItems={'center'} display={'flex'} justifyContent={'space-between'}>
        Create a new camp list
        <CloseButton onClick={() => setOpen(false)} />
      </DialogTitle>
      <div className="dialog-body">
        <div className="auth-input">
          <Input inputRef={inputRef} type="text" placeholder="Enter List Name" autoFocus />
        </div>
        <div className="button-wrapper">
          <Button
            testid={'save-button'}
            variant="secondary"
            icon={isLoading ? <CircularProgress size={'1rem'} style={{ color: 'black' }} /> : <Icons.SaveButtonIcon />}
            onClick={() => onSave()}
            title={'Save'}
            disabled={isLoading}
          />
        </div>
      </div>
      <Snackbar
        data-testid="error-message"
        open={errorMessage !== null}
        autoHideDuration={6000}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
        onClose={() => setErrorMessage(null)}
      >
        <Alert severity="error">{errorMessage}</Alert>
      </Snackbar>
    </CreateNewListDialogWrapper>
  )
}

export default CreateNewListDialog

// Styled Components
const CreateNewListDialogWrapper = styled(Dialog)`
  .dialog-body {
    padding: 0 1rem 1rem;

    .auth-input {
      padding: 1.5rem 0;
      border-top: 1px solid ${colors.brandGray300};
      border-bottom: 1px solid ${colors.brandGray300};
      margin-bottom: 1rem;
      min-width: 300px;

      @media (min-width: 1280px) {
        min-width: 375px;
      }

      label {
        margin-bottom: 0.5rem;
        display: block;
      }
    }

    .button-wrapper {
      display: flex;
      justify-content: flex-end;
      button {
        font-size: 1rem;
        border: 1px solid #a9b6fc;
      }
    }
  }
`
