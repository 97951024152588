import { Icons } from '@assets/svg'
import { colors, theme } from '@config/theme'
import { compareTimes, timeFormatter } from '@decorators/number-formatter.ts'
import { Camp } from '@libs/types/Camp.ts'
import { Box } from '@mui/material'
import styled, { ThemeProvider } from 'styled-components'

export const CampTimes = ({
  start_time,
  end_time,
  earliest_dropoff_time,
  latest_pickup_time,
  extended_earliest_dropoff_time,
  extended_latest_pickup_time,
}: Partial<Camp>) => {
  const formatTime = (time: string | null | undefined) => (time ? timeFormatter(time) : 'Unknown')

  const startTime = formatTime(start_time)
  const endTime = formatTime(end_time)
  const extendedLatestPickupTime = extended_latest_pickup_time
    ? timeFormatter(extended_latest_pickup_time)
    : extended_latest_pickup_time
  const extendedEarliestDropoffTime = extended_earliest_dropoff_time
    ? timeFormatter(extended_earliest_dropoff_time)
    : extended_earliest_dropoff_time

  let mainTime
  let earliestDropoffTime
  let latestPickupEnds

  //   The time section of the Camp Detail page should show:
  //   [start time] - [end time]
  //   If [end time] <= [start time], show “Unknown”
  //   If [start time] is null but [end time] is valid, show “Unknown - “[end time]
  //   If [end time] is null but [start time] is valid, show [start time]”- Unknown”
  //   If both [start time] and [end time] are null, show “Unknown”
  if (startTime !== 'Unknown' && endTime !== 'Unknown') {
    mainTime = compareTimes(endTime, startTime, '<=') ? 'Unknown' : `${startTime} - ${endTime}`
  } else if (startTime === 'Unknown' && endTime === 'Unknown') {
    mainTime = `Unknown`
  } else {
    mainTime = `${startTime} - ${endTime}`
  }

  // Drop off starts  [the earliest dropoff time]
  // If [the earliest dropoff time] is null or >= [start time], show “---”
  if (earliest_dropoff_time) {
    const curTime = timeFormatter(earliest_dropoff_time)
    earliestDropoffTime = compareTimes(curTime, startTime, '>=') ? '---' : curTime
  } else {
    earliestDropoffTime = '---'
  }

  // Pickup ends  [latest pickup time]
  // If [latest pickup time] is null or <= [end time], show “---”
  if (latest_pickup_time) {
    const curTime = timeFormatter(latest_pickup_time)
    latestPickupEnds = compareTimes(curTime, endTime, '<=') ? '---' : curTime
  } else {
    latestPickupEnds = '---'
  }

  // Before care  [extended earliest dropoff time] - [start time]
  // If [extended earliest dropoff time] is null or >= [start time], show “---”
  // If [start time] is null, show  [extended earliest dropoff time] - Unknown
  const beforeCare =
    !extendedEarliestDropoffTime || compareTimes(extendedEarliestDropoffTime, startTime, '>=')
      ? '---'
      : `${extendedEarliestDropoffTime} - ${startTime}`

  // After care  [end time] - [extended latest pickup time]
  // If [extended latest pickup time] is null, show “---”
  // If [extended latest pickup time] <= [end time], show “---”
  // If [end time] is null, show Unknown - [extended latest pickup time]
  const afterCare =
    !extendedLatestPickupTime || compareTimes(extendedLatestPickupTime, endTime, '<=')
      ? '---'
      : `${endTime} - ${extendedLatestPickupTime}`

  const careTimes = [
    { testId: 'dropoff_starts', label: 'Drop off starts', time: earliestDropoffTime },
    { testId: 'pickup_ends', label: 'Pickup ends', time: latestPickupEnds },
    { testId: 'before_care', label: 'Before care', time: beforeCare },
    { testId: 'after_care', label: 'After care', time: afterCare },
  ]
  return (
    <ThemeProvider theme={theme}>
      <CampTimesWrapper>
        <TimeSection>
          <TimeIcon>
            <Icons.ClockIcon />
          </TimeIcon>
          <MainTime data-testid={'start_end_time-value'}>{mainTime}</MainTime>
        </TimeSection>
        <CareTimes>
          {careTimes.map((careTime, index) => (
            <Box key={index} sx={{ display: 'flex' }}>
              <CareLabel>{careTime.label}</CareLabel>
              <CareTime data-testid={`${careTime.testId}-value`}>{careTime.time}</CareTime>
            </Box>
          ))}
        </CareTimes>
      </CampTimesWrapper>
    </ThemeProvider>
  )
}

// Styled Components
const CampTimesWrapper = styled.div`
  display: flex;
  justify-content: flex-start;
  flex-direction: column;
  flex: 1;
  @media ${({ theme }) => theme.device['md']} {
    margin-left: 0.5rem;
  }
`

const TimeSection = styled.div`
  display: flex;
  align-self: flex-start;
  margin-bottom: 10px;
`

const TimeIcon = styled.div`
  display: flex;
  align-items: center;
  margin-right: 8px;
`

const MainTime = styled.div`
  font-size: 20px;
  line-height: 30px;
  font-weight: 600;
  color: ${colors.brandGray700};
`

const CareTimes = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
  font-size: 1rem;
  color: ${colors.brandGray700};
`

const CareLabel = styled.div`
  font-weight: 600;
  text-align: left;
  flex: 1;
  color: ${colors.brandGray900};
`

const CareTime = styled.div`
  font-weight: 400;
  text-align: right;
`
