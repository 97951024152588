import { api, apiResponse } from '@libs/api'
import { City } from '@libs/types/City.ts'
import { Metro } from '@libs/types/Metro.ts'
import { getCities, getMetros, setCities, setMetros } from '@store/metro/metroSlice.ts'
import { call, CallEffect, put, PutEffect, SelectEffect, takeLatest } from 'redux-saga/effects'

function* loadMetros(): Generator<CallEffect | PutEffect | SelectEffect, void | any> {
  const { data: metros } = (yield call(api.getMetros)) as apiResponse<Metro[]>
  const { data: cities } = (yield call(api.getCities)) as apiResponse<City[]>
  yield put(setMetros(metros))
  yield put(setCities(cities))
}

// function* setMetro(metro): Generator<CallEffect | PutEffect | SelectEffect, void | any> {
//   yield put(setSelectedMetro(metro))
// }
export default function* metroSaga() {
  yield takeLatest([getMetros, getCities], loadMetros)
  // yield takeLatest(setSelectedMetro, setMetro)
}
