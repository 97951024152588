import { findEntityById } from '@decorators/findEntityById.ts'
import { useScript } from '@hooks'
import { api } from '@libs/api'
import { CircularProgress } from '@mui/material'
import { Box } from '@mui/system'
import { resetFilters } from '@store/campFilters/campFiltersSlice.ts'
import { getCampList } from '@store/campSearch/campSearchSlice.ts'
import { useAppDispatch, useAppSelector } from '@store/hooks.ts'
import { setSelectedMetro } from '@store/metro/metroSlice.ts'
import mixpanel from 'mixpanel-browser'
import { FC, useEffect, useState } from 'react'
import { useLocation } from 'react-router-dom'
import styled from 'styled-components'

const BlogWrapper = styled.div`
  margin-top: 61px;
  width: 100%;
`

const BlogPage: FC = () => {
  const location = useLocation()
  const isUserLoggedIn = api.isUserLoggedIn()
  const selectedMetro = useAppSelector((state) => state.metro.selectedMetro)
  const metros = useAppSelector((state) => state.metro.metros)
  const dispatch = useAppDispatch()
  const query = new URLSearchParams(location.search)
  const page = query.get('p')
  const [isLoaderVisible, setIsLoaderVisible] = useState<boolean>(true)

  useScript('https://io.dropinblog.com/embedjs/6bb5326d-d5bd-4e16-9522-40fc2232ef37.js?onload=consoleLog', () => {
    setIsLoaderVisible(false)
  })

  useEffect(() => {
    mixpanel.track_pageview({ page: page === 'camperoni-membership-options' ? 'MembershipPage' : 'BlogPage' })
  }, [])

  useEffect(() => {
    if (isUserLoggedIn && !selectedMetro) {
      const metroId = api.getUserMetro()
      if (metroId && metros.length > 0) {
        dispatch(setSelectedMetro(findEntityById(metroId, metros)))
        dispatch(resetFilters())
        dispatch(getCampList())
      }
    }
  }, [isUserLoggedIn, metros, selectedMetro])

  return (
    <BlogWrapper data-testid={'blog-wrapper'}>
      <div id="dib-posts">
        {isLoaderVisible && (
          <Box sx={{ margin: '0 auto', width: '40px' }}>
            <CircularProgress />
          </Box>
        )}
      </div>
    </BlogWrapper>
  )
}

export default BlogPage
