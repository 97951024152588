import { Camp } from '@libs/types/Camp.ts'
import { Grid } from '@mui/material'
import { CampItem } from '@stories/Theme/Card/CampItem/CampItem.tsx'

interface PaginatedItemProps {
  item: Camp | null
  setFavCampId: (id: number) => void
  isBoosted?: boolean
  campIndex?: number
  pageIndex?: number
  campPagePosition?: number
}

export const CampListItem = ({
  item,
  setFavCampId,
  isBoosted = false,
  campPagePosition,
  campIndex,
  pageIndex,
}: PaginatedItemProps) => {
  if (!item) {
    return null
  }
  return (
    <Grid item xs={12} sm={6} md={6} lg={3}>
      <CampItem
        id={item.id}
        title={item.name}
        ages_to={item.ages_to}
        ages_from={item.ages_from}
        badges={item.badges}
        bussing_options={item.bussing_options}
        daily_price={item.daily_price}
        day_length={item.day_length}
        half_day_combinable={item.half_day_combinable}
        facility_name={item.facility_name}
        image={item.image_url}
        registration_close_date={item.registration_close_date}
        registration_open_date={item.registration_open_date}
        dates={item.dates}
        providerId={item.corporate_provider}
        providerGoogleReview={item.provider_google_review}
        programProviderId={item.program_provider}
        corporate_provider_name={item.corporate_provider_name}
        setFavCampId={() => setFavCampId && setFavCampId(item.id)}
        is_favorite={item.is_favorite}
        isBoosted={isBoosted}
        campIndex={campIndex}
        pageIndex={pageIndex}
        campPagePosition={campPagePosition}
      />
    </Grid>
  )
}
