import { Icons } from '@assets/svg'
import { colors } from '@config/theme'
import { Metro } from '@libs/types/Metro.ts'
import { Box, Card, CardContent, CircularProgress, Grid, Typography } from '@mui/material'
import { useAppSelector } from '@store/hooks.ts'
import { Button } from '@stories/Theme/Button/Button.tsx'
import styled from 'styled-components'

import { CitiesMarquee } from '../../../components/CitiesMarquee/CitiesMarquee.tsx'

export const MetroCard = ({
  metro,
  isSettingMetro,
  handleClick,
}: {
  metro: Metro
  isSettingMetro: { [key: number]: boolean }
  handleClick: (metro: Metro) => void
}) => {
  const selectedMetro = useAppSelector((state) => state.metro.selectedMetro)

  return (
    <Grid key={metro.id} item xs={12} sm={12} md={6} lg={4}>
      <StyledCard>
        <StyledMainImage
          data-testid={`metro-${metro.id}-image`}
          src={metro.image_url}
          alt={metro.full_name}
          loading={'lazy'}
          onError={({ currentTarget }) => {
            currentTarget.onerror = null
            currentTarget.src =
              'https://camperoni-com-prod-cities-images.nyc3.cdn.digitaloceanspaces.com/default-city.jpg'
          }}
        />
        <StyledCardContent>
          <Box
            sx={{ width: '100%', display: 'flex', justifyContent: 'center' }}
            data-testid={`metro-${metro.id}-scrolling-city`}
          >
            <CitiesMarquee
              metro={metro}
              customWidth={'80%'}
              customFontWeight={400}
              customFontSize={'1rem'}
              testId={`metro-${metro.id}-scrolling-city-value-`}
            />
          </Box>
          <StyledCardTitle data-testid={`metro-title-value-${metro.id}`}>{metro.full_name}</StyledCardTitle>
          <CampCounterBox>
            <Box sx={{ flex: 1 }}>
              <StyledTitle data-testid={`${metro.id}-camps`}>{metro.camps_count}</StyledTitle>
              <Counter>camps</Counter>
            </Box>
            <FromText>from</FromText>
            <Box sx={{ flex: 1 }}>
              <StyledTitle data-testid={`${metro.id}-providers`}>{metro.providers_count}</StyledTitle>
              <Counter>camp providers</Counter>
            </Box>
          </CampCounterBox>
          <ButtonContainer>
            <Button
              disabled={
                selectedMetro?.id === metro.id ||
                (isSettingMetro[metro.id] ? false : Object.values(isSettingMetro).some((value) => value))
              }
              testid={`metro_select_button_${metro.id}`}
              fullWidthOnSmallScreen
              title={selectedMetro?.id === metro.id ? 'Selected' : 'Select'}
              rounded={true}
              style={{ padding: '0.5rem 1.2rem', height: '40px' }}
              size="small"
              titleStyle={{ fontSize: '1rem' }}
              onClick={() => handleClick(metro)}
              icon={
                selectedMetro?.id === metro.id ? null : isSettingMetro[metro.id] ? (
                  <CircularProgress size={'1rem'} style={{ color: '#fff' }} />
                ) : (
                  <Icons.NavigationArrowIcon />
                )
              }
            />
          </ButtonContainer>
        </StyledCardContent>
      </StyledCard>
    </Grid>
  )
}

const ButtonContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`

const StyledCard = styled(Card)`
  border-radius: 12px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1) !important;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
`

const StyledCardContent = styled(CardContent)`
  padding: 1rem !important;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 0.5rem;
`
const StyledCardTitle = styled(Typography)`
  font-family: 'Ubuntu', sans-serif !important;
  color: ${colors.brandGray900};
  font-size: 2.25rem !important;
  line-height: 2.75rem !important;
  font-weight: 700 !important;
  letter-spacing: -0.1125rem;
`

const StyledMainImage = styled.img`
  height: 263px;
  align-self: stretch;
`

const CampCounterBox = styled(Box)`
  width: 90% !important;
  display: flex;
  gap: 0.5rem;
  align-items: flex-start;
  justify-content: space-between;
  margin-bottom: 0.5rem;
  padding: 0.5rem 0;
  border-top: 1px solid ${colors.brandGray400};
  border-bottom: 1px solid ${colors.brandGray400};

  @media${({ theme }) => theme.device['sm']} {
    width: 75% !important;
  }
`

const StyledTitle = styled.p`
  font-family: 'Ubuntu', 'Inter', sans-serif;
  font-weight: 700;
  font-size: 1.875rem;
  line-height: 2.25rem;
  letter-spacing: -0.09375rem;
  color: ${colors.brandGray900};
  text-align: center;
  margin-bottom: 2px;
`

const Counter = styled.span`
  text-align: center;
  display: block;
  font-size: 1rem;
  font-weight: 400;
  color: ${colors.brandGray900};
  line-height: 1.5rem;
`

const FromText = styled.span`
  text-align: center;
  display: block;
  font-size: 1rem;
  font-weight: 400;
  color: ${colors.brandGray900};
  line-height: 1.5rem;
  margin-top: 0.5rem;
`
