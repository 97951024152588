import { decorateEntityIds } from '@decorators/decorateEntityIds.ts'
import { api, apiResponse } from '@libs/api'
import { Camp, FavoriteCampItem } from '@libs/types/Camp.ts'
import { UserList } from '@libs/types/UserList.ts'
import { call, CallEffect, put, PutEffect, SelectEffect, takeLatest } from 'redux-saga/effects'

import { getUserList, setUserList, setUserListLoading } from './userListSlice'

export function* userList(): Generator<CallEffect | PutEffect | SelectEffect, void | any> {
  yield put(setUserListLoading(true))
  const { data: userList } = (yield call(api.getUserList)) as apiResponse<UserList[]>

  const uniqueCampIds = [...new Set(userList.flatMap((item) => item.camps))]

  const campsResponse = (yield call(api.getCampsForSpecificIds, uniqueCampIds)) as apiResponse<Camp[]>

  const camps = campsResponse.data.map((el: Camp) => {
    return {
      id: el.id,
      name: el.name,
      dates: el.dates,
      day_length: el.day_length,
      half_day_combinable: el.half_day_combinable,
      earliest_dropoff_time: el.earliest_dropoff_time,
      latest_pickup_time: el.latest_pickup_time,
    }
  })

  userList.map((list) => {
    list.camps_decorated = decorateEntityIds<FavoriteCampItem>(list.camps, camps)
  })

  yield put(setUserList(userList))
  yield put(setUserListLoading(false))
}

export default function* userListSaga() {
  yield takeLatest(getUserList, userList)
}
