import Eventbus from '@libs/eventbus'
import FilterListIcon from '@mui/icons-material/FilterList'
import RefreshOutlinedIcon from '@mui/icons-material/RefreshOutlined'
import { Box, Button, Hidden, Typography, useTheme } from '@mui/material'
import { resetFilters } from '@store/campFilters/campFiltersSlice'
import { toggleFilterDrawer } from '@store/campSearch/campSearchSlice.ts'
import { useAppDispatch, useAppSelector } from '@store/hooks.ts'

import Sort from './CampListSidebar/Filters/Sort'
import CampResults from './CampResults.tsx'

const CampListSortButton = () => {
  const theme = useTheme()
  const dispatch = useAppDispatch()
  const campListFiltered = useAppSelector((state) => state.campSearch.campListFiltered)
  const isLoading = useAppSelector((state) => state.campSearch.isLoading)

  function handleFilterDrawerToggle() {
    dispatch(toggleFilterDrawer())
  }
  function handleResetFilters() {
    dispatch(resetFilters())
    Eventbus.trigger(Eventbus.RESET_FILTERS)
    Eventbus.trigger(Eventbus.DISTANCE_CLEAR_FILTER)

    Eventbus.trigger(Eventbus.MIXPANEL_FILTER_ACTION, {
      filter: 'Reset All Filters',
    })
  }

  return (
    <>
      <Hidden lgUp>
        <Box pb={3} display="flex" justifyContent="space-between" alignItems={'center'}>
          <Box display={'flex'} alignItems={'center'} className={'x-results-clear-filters'}>
            <CampResults CampListFilteredLength={campListFiltered.length} isLoading={isLoading} />
            {!isLoading && (
              <Button color="inherit" onClick={handleResetFilters} startIcon={<RefreshOutlinedIcon />} sx={{ mr: 1 }}>
                Clear All
              </Button>
            )}
          </Box>

          <Button
            data-testid={'sort-filter-button'}
            startIcon={<FilterListIcon />}
            onClick={handleFilterDrawerToggle}
            size="large"
            variant="outlined"
            sx={{ borderRadius: theme.shape.borderRadius }}
          >
            Sort & Filter
          </Button>
        </Box>
      </Hidden>
      <Hidden lgDown>
        <Box display={'flex'} justifyContent={'space-between'} alignItems={'center'}>
          <Box display={'flex'} alignItems={'center'}>
            <CampResults CampListFilteredLength={campListFiltered.length} isLoading={isLoading} />
            {!isLoading && (
              <Button color="inherit" onClick={handleResetFilters} startIcon={<RefreshOutlinedIcon />} sx={{ mr: 1 }}>
                Clear All
              </Button>
            )}
          </Box>
          <Box pb={3} display="flex" justifyContent="end" alignItems="center">
            <Typography variant="body1" mr={1.5}>
              Sort by
            </Typography>
            <Sort />
          </Box>
        </Box>
      </Hidden>
    </>
  )
}

export default CampListSortButton
