import { EllipseEnum, EllipseProps } from '@libs/types/Ellipse.ts'
import { FC } from 'react'
import styled from 'styled-components'

const Ellipse: FC<EllipseProps> = ({ type = EllipseEnum['default'] }) => {
  return <EllipseWrapper type={type} data-testid={'header-orange-ellipse'} />
}

export default Ellipse

// Styled Components
const EllipseWrapper = styled.div<EllipseProps>`
  position: absolute;
  right: 0;
  top: 0;
  width: 190px;
  overflow: hidden;
  height: 150px;
  border-bottom-left-radius: 500px;
  z-index: ${({ type }) => (type === EllipseEnum.auth ? '-1' : 0)};
  @media${({ theme }) => theme.device['md']} {
    width: ${({ type }) => (type === EllipseEnum.big ? '300px' : type === EllipseEnum.default ? '335px' : '305px')};
    height: ${({ type }) => (type === EllipseEnum.big ? '450px' : type === EllipseEnum.default ? '185px' : '245px')};
  }
  &::before {
    content: '';
    position: absolute;
    border-radius: 50%;
    background-color: ${({ theme }) => theme.colors['brand-yellow']};
    width: 175px;
    height: 175px;
    right: 15px;
    top: -97px;
    z-index: 1;
    @media${({ theme }) => theme.device['md']} {
      width: ${({ type }) =>
        type === EllipseEnum.big ? '513.113px' : type === EllipseEnum.default ? '285px' : '285px'};
      height: ${({ type }) =>
        type === EllipseEnum.big ? '513.113px' : type === EllipseEnum.default ? '285px' : '285px'};
      right: ${({ type }) => (type === EllipseEnum.big ? '-217.11px' : type === EllipseEnum.default ? '53px' : '20px')};
      top: ${({ type }) =>
        type === EllipseEnum.big ? '-196.44px' : type === EllipseEnum.default ? '-178px' : '-161px'};
      z-index: ${({ type }) => (type === EllipseEnum.big ? 2 : 1)};
    }
  }
  &::after {
    content: '';
    position: absolute;
    border-radius: 50%;
    background-color: ${({ theme }) => theme.colors['brand-green']};
    width: 235px;
    height: 235px;
    right: -147px;
    top: -82.14px;
    z-index: 2;
    @media${({ theme }) => theme.device['md']} {
      width: ${({ type }) =>
        type === EllipseEnum.big ? '160.08px' : type === EllipseEnum.default ? '357.35px' : '357.35px'};
      height: ${({ type }) =>
        type === EllipseEnum.big ? '160.08px' : type === EllipseEnum.default ? '357.35px' : '357.35px'};
      right: ${({ type }) => (type === EllipseEnum.big ? '-45px' : type === EllipseEnum.default ? '-168px' : '-208px')};
      top: ${({ type }) =>
        type === EllipseEnum.big ? '280.12px' : type === EllipseEnum.default ? '-178px' : '-115.23px'};
      z-index: ${({ type }) => (type === EllipseEnum.big ? 1 : 2)};
    }
  }
`
