import { api } from '@libs/api'
import { getRequirementList } from '@store/campPage/campPageSlice.ts'
import { getCampList } from '@store/campSearch/campSearchSlice.ts'
import { useAppDispatch, useAppSelector } from '@store/hooks.ts'
import { getMetros } from '@store/metro/metroSlice.ts'
import { getSchoolBreakList } from '@store/schoolBreaks/schoolBreaksSlice'
import { FC, ReactNode, useEffect, useLayoutEffect } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'
import styled from 'styled-components'

import Footer from '../components/Footer'
import Header from '../components/Header'
import redis from '../libs/lockr.ts'

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  min-height: 100vh;
`

const MainContainer = styled.main`
  max-width: 1440px;
  width: 100%;
  margin: 0 auto;
`

const FooterContainer = styled.footer`
  margin-top: auto;
`
const HomepageLayout: FC<{ children: ReactNode; pageTitle?: string | undefined; requireAuthentication?: boolean }> = ({
  children,
  pageTitle,
  requireAuthentication,
}) => {
  const dispatch = useAppDispatch()
  const navigator = useNavigate()
  const selectedMetro = useAppSelector((state) => state.metro.selectedMetro)

  const { pathname } = useLocation()
  useLayoutEffect(() => {
    window.scrollTo(0, 0)
  }, [pathname])

  const query = new URLSearchParams(location.search)
  const blogParams = query.get('p')

  const isLoggedIn = api.isUserLoggedIn()

  useEffect(() => {
    dispatch(getMetros())
    if (requireAuthentication && !isLoggedIn) {
      redis.setEx('redirectUrl', 3600, window.location.pathname + window.location.search)
      return navigator('/login')
    }

    if (isLoggedIn && selectedMetro) {
      dispatch(getCampList())
    }

    dispatch(getRequirementList())
    dispatch(getSchoolBreakList())
  }, [isLoggedIn])

  return (
    <Wrapper>
      <Header pageTitle={blogParams === 'camperoni-membership-options' ? 'Memberships' : (pageTitle ?? 'Home')} />
      <MainContainer>{children}</MainContainer>
      <FooterContainer>
        <Footer />
      </FooterContainer>
    </Wrapper>
  )
}

export default HomepageLayout
