export const isHalfDay = (dayLength: 'FULL' | 'HALF_AM' | 'HALF_PM' | 'OVERNIGHT'): boolean => {
  return dayLength === 'HALF_PM' || dayLength === 'HALF_AM'
}

export const dayLength = (
  dayLength: 'FULL' | 'HALF_AM' | 'HALF_PM' | 'OVERNIGHT',
  isHalfDayCombinable: boolean
): string => {
  const dictionary = {
    FULL: 'Full day',
    Full: 'Full day',
    HALF_AM: 'Half day AM',
    HALF_PM: 'Half day PM',
    OVERNIGHT: 'Overnight',
  }

  const dayLengthLabel = dictionary[dayLength]
  if (isHalfDay(dayLength) && isHalfDayCombinable) {
    return `${dayLengthLabel} (combinable)`
  }
  return dayLengthLabel
}
