import { findEntityById } from '@decorators/findEntityById.ts'
import { api } from '@libs/api'
import { Metro } from '@libs/types/Metro.ts'
import { User } from '@libs/types/User.ts'
import * as Sentry from '@sentry/react'
import { initialState, resetFilters } from '@store/campFilters/campFiltersSlice.ts'
import { setCampList, setCampListLoading, setFilteredCampList } from '@store/campSearch/campSearchSlice.ts'
import { useAppDispatch, useAppSelector } from '@store/hooks.ts'
import { setSelectedMetro } from '@store/metro/metroSlice.ts'
import { store } from '@store/store.ts'
import mixpanel from 'mixpanel-browser'
import { ReactNode, useEffect, useRef } from 'react'

import { MetroSyncContext } from '../contexts/MetroSyncContext'
import redis from '../libs/lockr.ts'

type Props = {
  children: ReactNode
}

export const MetroSyncProvider = ({ children }: Props) => {
  const dispatch = useAppDispatch()
  const metros = useAppSelector((state) => state.metro.metros)
  const selectedMetro = useAppSelector((state) => state.metro.selectedMetro)
  const intervalRef = useRef<number | null>(null)
  const broadcastChannelRef = useRef<BroadcastChannel | null>(null)
  const isUserLoggedIn = api.isUserLoggedIn()

  const handleMetroChange = () => {
    dispatch(setCampListLoading(true))
    const intervalLoadingState = setInterval(() => {
      const isLoadingStateCamps = store.getState().campSearch.isLoading
      if (isLoadingStateCamps) {
        clearInterval(intervalLoadingState)
        dispatch(resetFilters())
        const intervalClearFilters = setInterval(() => {
          const latestFiltersState = store.getState().campFilters.filters

          if (JSON.stringify(latestFiltersState) === JSON.stringify(initialState.filters)) {
            clearInterval(intervalClearFilters)
            if (intervalRef.current) {
              clearInterval(intervalRef.current)
            }
            setTimeout(() => window.location.reload(), 0)
          }
        }, 100)
      }
    }, 100)
  }

  /**
   * @description We need to skip polling in case of:
   * 1) Document doesn't have focus
   * 2) if metros haven't arrived yet
   * 3) in case the redisKey is still active & not staled
   * 4) if it's running inside e2e tests
   */
  const shouldSkipPolling = (): boolean => {
    const isE2ETest = typeof window !== 'undefined' && ('__PLAYWRIGHT__' in window || navigator.webdriver)
    return (
      !document.hasFocus() ||
      (isE2ETest && !navigator.userActivation.isActive) ||
      (redis.get('api:getUserByKey') && !redis.isStale('api:getUserByKey')) ||
      !metros.length
    )
  }

  /**
   * @description Handles Broadcast message
   */
  useEffect(() => {
    if (typeof window === 'undefined') {
      return
    }

    broadcastChannelRef.current = new BroadcastChannel('metro-sync')
    broadcastChannelRef.current.onmessage = (event) => {
      if (event.data?.type === 'METRO_CHANGED') {
        handleMetroChange()
      }
    }
    return () => {
      broadcastChannelRef.current?.close()
    }
  }, [])

  /**
   * @description Handles polling if user is logged in
   */
  useEffect(() => {
    if (!api.isUserLoggedIn()) {
      return
    }

    const pollMetroChange = async () => {
      if (shouldSkipPolling()) {
        return
      }

      if (intervalRef.current) {
        clearInterval(intervalRef.current)
      }

      try {
        api
          .getUserByKey()
          .then((apiResponse) => apiResponse.data)
          .then((remoteUser) => {
            if (remoteUser.metro !== selectedMetro?.id) {
              const newMetro = findEntityById<Metro>(remoteUser.metro, metros)

              if (!newMetro) {
                Sentry.captureMessage(
                  `Metro was unable to be found in ${metros.length} metros, using: ${remoteUser.metro} id`
                )
              }

              const previousSelectedMetro = selectedMetro ? selectedMetro : { name: null, id: null }

              dispatch(setCampList([]))
              dispatch(setFilteredCampList([]))
              dispatch(resetFilters())
              dispatch(setSelectedMetro(newMetro))
              dispatch(setCampListLoading(true))

              mixpanel.track('Metro Update Detected', {
                'Old Metro': previousSelectedMetro.name,
                'Old Metro ID': previousSelectedMetro.id,
                'New Metro': newMetro?.name,
                'New Metro ID': newMetro?.id,
              })

              Sentry.captureMessage(
                `Metro Update Detected. Old Metro: ${previousSelectedMetro.name} - Old Metro ID: ${previousSelectedMetro.id} - New Metro: ${newMetro?.name} - New Metro ID: ${newMetro?.id}`,
                {
                  fingerprint: ['metro-update-detected-event-fingerprint'],
                }
              )

              const interval = setInterval(() => {
                // Directly get from Redux store
                const latestSelectedMetro = store.getState().metro.selectedMetro
                const latestCampList = store.getState().campSearch.campList
                const latestCampListFiltered = store.getState().campSearch.campListFiltered
                const user = redis.get('user') as User
                let newMetroId = null
                if (newMetro?.id) {
                  newMetroId = newMetro.id
                }

                if (
                  latestCampList.length === 0 &&
                  latestCampListFiltered.length === 0 &&
                  (latestSelectedMetro ? latestSelectedMetro.id === newMetroId : latestSelectedMetro === newMetroId) &&
                  user.metro === newMetroId
                ) {
                  clearInterval(interval)
                  broadcastChannelRef.current?.postMessage({
                    type: 'METRO_CHANGED',
                    newMetro: newMetro,
                  })
                  setTimeout(() => {
                    window.location.reload()
                  }, 50)
                }
              }, 100)
            }
          })
          .finally(() => {
            window.refreshInterval = intervalRef.current = window.setInterval(() => pollMetroChange(), 5000)
          })
      } catch (error) {
        console.error('Failed to fetch user data:', error)
        Sentry.captureException(error)
      }
    }

    window.refreshInterval = intervalRef.current = window.setInterval(() => pollMetroChange(), 5000)

    return () => {
      if (intervalRef.current) {
        clearInterval(intervalRef.current)
      }
    }
  }, [isUserLoggedIn, selectedMetro])

  return <MetroSyncContext.Provider value={{ broadcastChannelRef }}>{children}</MetroSyncContext.Provider>
}
