import { colors, theme } from '@config/theme'
import styled, { ThemeProvider } from 'styled-components'

export const DatesOneDay = ({ date }: { date: string }) => {
  const dateObj = new Date(date)
  const day = dateObj.getUTCDate()
  const weekday = dateObj.toLocaleDateString('en-US', { weekday: 'long', timeZone: 'UTC' })
  const monthYear = dateObj.toLocaleDateString('en-US', { month: 'short', year: 'numeric', timeZone: 'UTC' })

  return (
    <ThemeProvider theme={theme}>
      <DateItem data-testid={'date-card'} data-status="single_day">
        <Month data-testid="date_module_1day_month_year_element-value">{monthYear}</Month>
        <Day data-testid="date_module_1day_date_element-value">{day}</Day>
        <Weekday data-testid="date_module_1day_day_element-value">{weekday}</Weekday>
      </DateItem>
    </ThemeProvider>
  )
}

// Styled Components
const DateItem = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 8px;

  @media${({ theme }) => theme.device['md']} {
    width: 100%;
  }
`

const Month = styled.div`
  font-size: 16px;
  line-height: 24px;
  color: ${colors.brandGray700};
`

const Day = styled.div`
  font-size: 36px;
  font-weight: 600;
  line-height: 30px;
  color: ${colors.brandGray900};
`

const Weekday = styled.div`
  font-size: 16px;
  line-height: 24px;
  color: ${colors.brandGray700};
`
