import { colors } from '@config/theme'
import React, { useState } from 'react'
import { FaEye, FaEyeSlash } from 'react-icons/fa'
import styled from 'styled-components'

export type InputTypeVariant = 'text' | 'email' | 'password'
export type RoundedVariant = 'small' | 'large'

export interface InputProps {
  id?: string
  value?: string
  onChange?: (event: React.ChangeEvent<HTMLInputElement>) => void
  placeholder?: string
  disabled?: boolean
  type?: InputTypeVariant
  defaultValue?: string
  rounded?: RoundedVariant
  className?: string
  style?: React.CSSProperties
  fontSize?: string
  required?: boolean
  name?: string
  autoFocus?: boolean
  inputRef?: React.MutableRefObject<null>
}

const StyledInput = styled.div<Partial<InputProps>>`
  position: relative;
  display: flex;
  align-items: center;
`

const InputField = styled.input<{
  rounded?: string
  fontSize?: string
}>`
  flex: 1;
  padding: 0.5rem 0.8rem;
  line-height: 1;
  ${({ fontSize }) => fontSize && `font-size: ${fontSize};`}
  border-radius: ${({ rounded }) => (rounded === 'small' ? '8px' : '50px')};
  border: 1px solid ${colors.brandGray300};
  color: ${colors.brandGray900};
  &:focus {
    border: 1px solid ${colors.brandBlue};
  }
  &::placeholder {
    color: ${colors.brandGray300};
  }
  ${(props) => props.disabled && `background-color: rgba(208, 213, 221, 0.38);`};
`

const VisibilityToggle = styled.span`
  position: absolute;
  top: 50%;
  right: 10px;
  transform: translateY(-50%);
  background: none;
  border: none;
  outline: none;
  cursor: pointer;
`

export const Input = ({
  id,
  value,
  onChange,
  placeholder = '',
  disabled = false,
  type = 'text',
  rounded = 'small',
  defaultValue,
  className,
  fontSize,
  style,
  name,
  required,
  inputRef,
  autoFocus,
}: InputProps) => {
  const [show, setShow] = useState(false)

  const toggleVisibility = () => {
    setShow(!show)
  }

  return (
    <StyledInput>
      <InputField
        id={id}
        value={value}
        defaultValue={defaultValue}
        ref={inputRef}
        onChange={onChange}
        placeholder={placeholder}
        disabled={disabled}
        type={type === 'password' && show ? 'text' : type}
        rounded={rounded}
        className={className}
        style={style}
        fontSize={fontSize}
        name={name}
        required={required}
        autoFocus={autoFocus}
      />
      {type === 'password' && (
        <VisibilityToggle onClick={toggleVisibility}>
          {show ? (
            <FaEyeSlash style={{ color: colors.brandGray300 }} />
          ) : (
            <FaEye style={{ color: colors.brandGray300 }} />
          )}
        </VisibilityToggle>
      )}
    </StyledInput>
  )
}
