import { findEntityById } from '@decorators/findEntityById.ts'
import { api } from '@libs/api'
import { Metro } from '@libs/types/Metro.ts'
import { Alert, Grid, Snackbar, Typography } from '@mui/material'
import { User } from '@sentry/react'
import { resetFilters } from '@store/campFilters/campFiltersSlice.ts'
import { setCampList, setCampListLoading, setFilteredCampList } from '@store/campSearch/campSearchSlice.ts'
import { useAppDispatch, useAppSelector } from '@store/hooks'
import { setSelectedMetro } from '@store/metro/metroSlice.ts'
import { store } from '@store/store'
import { getUserList } from '@store/userList/userListSlice'
import mixpanel from 'mixpanel-browser'
import { ReactNode, useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import styled from 'styled-components'

import { useMetroSync } from '../hooks/useMetroSync.tsx'
import redis from '../libs/lockr.ts'
import { MetroCard } from '../stories/Components/MetroCard/MetroCard.tsx'
import ChooseMetroSkeleton from './ChooseMetro.skeleton.tsx'

const ChooseMetro = () => {
  const navigate = useNavigate()
  const dispatch = useAppDispatch()
  const metros = useAppSelector((state) => state.metro.metros)
  const [isLoading, setIsLoading] = useState(true)
  const { broadcastChannelRef } = useMetroSync()
  const [isSettingMetro, setIsSettingMetro] = useState<{ [key: number]: boolean }>({})
  const [errorMessage, setErrorMessage] = useState<ReactNode>(null)

  useEffect(() => {
    const validateUserMetro = async () => {
      try {
        mixpanel.track_pageview({ page: 'ChooseMetro' })

        const isLoggedIn = api.isUserLoggedIn()

        if (!isLoggedIn) {
          navigate('/register')
          return
        }

        api
          .updateUserFromDB()
          .then((updatedUser) => {
            if (updatedUser.metro !== null && updatedUser.metro !== undefined) {
              if (metros.length) {
                dispatch(resetFilters())
                dispatch(setSelectedMetro(findEntityById<Metro>(updatedUser.metro, metros)))
              }
            }
          })
          .finally(() => {
            if (metros.length > 0) {
              setIsLoading(false)
            }
          })
      } catch (error) {
        console.error('An error occurred:', error)
      } finally {
        dispatch(getUserList())
      }
    }

    validateUserMetro()
  }, [metros])

  const handleClick = async (metro: Metro) => {
    // If the metro is already loading, return early
    if (isSettingMetro[metro.id]) {
      return
    }

    try {
      setIsSettingMetro((prev) => ({ ...prev, [metro.id]: true }))
      redis.expires('api:getUserByKey', Number(import.meta.env?.VITE_CACHE_TTL || 120))
      await api.setUserMetro(metro.id)
      redis.expires('api:getUserByKey', Number(import.meta.env?.VITE_CACHE_TTL || 120))

      await Promise.all([
        dispatch(setCampList([])),
        dispatch(setFilteredCampList([])),
        dispatch(setSelectedMetro(metro)),
        dispatch(setCampListLoading(true)),
      ])

      const interval = setInterval(() => {
        const user = redis.get('user') as User
        // Directly get from Redux store
        const latestSelectedMetro = store.getState().metro.selectedMetro
        const latestCampList = store.getState().campSearch.campList
        const latestCampListFiltered = store.getState().campSearch.campListFiltered
        if (
          latestCampList.length === 0 &&
          latestCampListFiltered.length === 0 &&
          latestSelectedMetro &&
          latestSelectedMetro.id === metro.id &&
          user.metro === metro.id
        ) {
          clearInterval(interval)
          if (broadcastChannelRef) {
            broadcastChannelRef.current?.postMessage({
              type: 'METRO_CHANGED',
              newMetro: metro,
            })
          }
          setTimeout(() => {
            const redirectUrl = redis.get('redirectUrl')
            if (redirectUrl && typeof redirectUrl === 'string' && redirectUrl !== location.pathname) {
              window.location.href = redirectUrl
            } else {
              window.location.href = '/'
            }
          }, 50)
        }
      }, 100)
    } catch (error: any) {
      setErrorMessage(
        <p>
          Sorry, we couldn’t change your location. Please try again. If the problem persists, please contact us at{' '}
          <a href="mailto:email@camperoni.com">email@camperoni.com</a>
        </p>
      )
    } finally {
      setIsSettingMetro((prev) => ({ ...prev, [metro.id]: false }))
    }
  }

  return (
    <Container>
      <Typography variant="h4" gutterBottom my={6}>
        Choose your location
      </Typography>
      {isLoading ? (
        <ChooseMetroSkeleton />
      ) : (
        <>
          <Grid container columnSpacing={{ xs: 0, sm: 4 }} rowSpacing={4} data-testid={'metro_title'}>
            {metros
              .filter((metro) => metro.active)
              .map((metro, index: number) => (
                <MetroCard key={index} metro={metro} isSettingMetro={isSettingMetro} handleClick={handleClick} />
              ))}
          </Grid>
        </>
      )}
      <Snackbar
        data-testid="error-text"
        open={errorMessage !== null}
        autoHideDuration={6000}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
        onClose={() => setErrorMessage(null)}
        sx={{ width: '100%', maxWidth: 400 }}
      >
        <Alert severity="error">{errorMessage}</Alert>
      </Snackbar>
    </Container>
  )
}

export default ChooseMetro

// Styled Components
const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 2rem 1rem;

  @media${({ theme }) => theme.device['sm']} {
    padding: 2rem;
  }
`
