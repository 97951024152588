import { api } from '@libs/api'
import { useEffect } from 'react'
import { useNavigate } from 'react-router-dom'

const useRedirectIfNoUserMetro = () => {
  const navigate = useNavigate()
  const isUserLoggedIn = api.isUserLoggedIn()
  const metroId = api.getUserMetro()

  useEffect(() => {
    if (!isUserLoggedIn) {
      return
    }

    if (metroId === null) {
      return navigate('/choose-your-metro')
    }
  }, [isUserLoggedIn])
}

export default useRedirectIfNoUserMetro
