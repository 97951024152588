import { theme } from '@config/theme'
import { Camp } from '@libs/types/Camp.ts'
import { Box, useMediaQuery } from '@mui/material'
import { Button } from '@stories/Theme/Button/Button'
import moment from 'moment'
import React from 'react'
import { GoLinkExternal } from 'react-icons/go'
import styled from 'styled-components'

import Eventbus from '../../libs/eventbus.ts'
import AddCalendar from './AddCalendar'
import AddToListAndShareButtons from './AddToListAndShareButtons'
import AddViaEasyEnroll from './AddViaEasyEnroll.tsx'
import FixedFooterShareButtons from './components/FixedFooterShareButtons.tsx'

export const CampDetails: React.FC<{ data: Camp; onAddToFavoriteButtonClick: () => void }> = (props: {
  data: Camp
  onAddToFavoriteButtonClick: () => void
}) => {
  const data = props.data
  const isLargeScreen: boolean = useMediaQuery(theme.device['md'])
  const isSmallScreen: boolean = !isLargeScreen
  const isCalendarVisible = data.registration_status === 'NOT YET OPEN' && moment(data.registration_open_date).isAfter()

  const calendarData = React.useMemo(() => {
    const name = data.name
    const id = data.id
    const location = `${data.facility_name} (${data.address})`
    const providerId = data.corporate_provider
    const registrationTimezone = data.registration_timezone
    let startDate,
      endDate,
      startTime,
      endTime,
      registrationOpenDate,
      registrationOpenTime,
      registrationCloseDate,
      registrationCloseTime,
      registrationLink = ''
    if (data.dates && data.dates.length > 0) {
      startDate = data.dates[0]
      endDate = data.dates[data.dates.length - 1]
    }
    if (data.start_time) {
      startTime = data.start_time
    }
    if (data.end_time) {
      endTime = data.end_time
    }
    if (data.registration_open_date) {
      registrationOpenDate = data.registration_open_date
    }
    if (data.registration_open_time) {
      registrationOpenTime = data.registration_open_time
    }
    if (data.registration_close_date) {
      registrationCloseDate = data.registration_close_date
    }
    if (data.registration_close_time) {
      registrationCloseTime = data.registration_close_time
    }
    if (data.registration_link) {
      registrationLink = data.registration_link
    }

    return {
      id,
      providerId,
      name,
      location,
      startDate,
      endDate,
      startTime,
      endTime,
      registrationOpenDate,
      registrationOpenTime,
      registrationCloseDate,
      registrationCloseTime,
      registrationLink,
      registrationTimezone,
    }
  }, [data.dates, data.start_time, data.end_time])

  return (
    <CampDetailsWrapper>
      {isLargeScreen && (
        <div className={'row'}>
          {data.registration_status === 'NOT YET OPEN' && moment(data.registration_open_date).isAfter() && (
            <Box sx={{ marginRight: '6px' }}>
              <AddCalendar data={calendarData} />
            </Box>
          )}
          {data.registration_status === 'OPEN' &&
            data.camperoni_registration_link &&
            data.camperoni_registration_link.length > 0 && (
              <AddViaEasyEnroll
                campId={data.id}
                providerId={data.corporate_provider}
                camperoni_registration_link={data.camperoni_registration_link}
              />
            )}
          <AddToListAndShareButtons
            size="large"
            withShareButton
            onClick={props.onAddToFavoriteButtonClick}
            elTestId="add_to_list_bottom"
          />
        </div>
      )}

      {data.registration_link && isLargeScreen && (
        <div className="row link-section">
          <a
            data-testid="provider_website_link"
            href={data.registration_link}
            target={'_blank'}
            className={'link blue'}
            rel="noreferrer"
            onClick={() => {
              Eventbus.trigger(Eventbus.MIXPANEL_CTA_CLICKED, {
                value: 'Clicked Provider Website Link',
                properties: {
                  'Provider ID': data.corporate_provider,
                  'Camp ID': data.id,
                },
              })
            }}
          >
            <Button
              className="blue"
              icon={<GoLinkExternal size="1rem" />}
              size="small"
              style={{ border: 'none', paddingLeft: 0, justifyContent: 'start', flex: 0, paddingRight: '0.5rem' }}
              title=""
              variant="secondary"
            />
            <span data-testid="provider_website_link_text-value">Provider Website</span>
          </a>
        </div>
      )}
      {isSmallScreen && (
        <FixedFooterShareButtons
          calendarData={isCalendarVisible ? calendarData : undefined}
          onAddToFavoritesClicked={props.onAddToFavoriteButtonClick}
          registrationStatus={data.registration_status}
          campId={data.id}
          providerId={data.corporate_provider}
          easyEnrollLink={data.camperoni_registration_link}
        />
      )}
    </CampDetailsWrapper>
  )
}

// Styled Components
const CampDetailsWrapper = styled.section`
  .blue {
    color: ${({ theme }) => theme.colors['brand-blue']};
  }

  .disabled {
    color: ${({ theme }) => theme.colors['brand-gray']};
    cursor: default;
  }

  .row {
    display: flex;
    margin: 12px 0;

    &.no-bottom-margin {
      margin: 12px 0 0;
    }
  }

  span {
    align-items: center;
    flex: 1;
  }

  .bold {
    display: flex;
    font-weight: bold;
  }

  .calendar {
    .gray {
      color: ${({ theme }) => theme.colors['brand-gray-400']};
    }

    span {
      display: inline-block;
      margin-right: 10px;
    }
  }

  .margin-top {
    margin-top: 10px;
  }

  .link {
    align-items: center;
    display: flex;

    span {
      flex: 0 2 auto;
    }
  }

  .link-section {
    cursor: pointer;
  }

  .align-items-start {
    align-items: flex-start;
  }

  .margin-bottom {
    margin-bottom: 10px;
  }
  .hidden {
    display: none;
  }
`
